import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete';
import Table from './../Table'
import { Grid, TextField, Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import CurrencyInput from './../mask/CurrencyInput'
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import PrintIcon from '@material-ui/icons/Print';

const formataSituacao = (situacao) => {
  if(situacao === 'AGUARDANDO_PAGAMENTO'){
    return 'Aguardando Pagamento'
  }

  if(situacao === 'FINALIZADO'){
    return 'Finalizado'
  }

  if(situacao === 'CANCELADO'){
    return 'Cancelado'
  }

  return situacao
  
}

const FormNotificacao = ({ dados, updateField, unidades, produtos, produto_selecionado, updateFieldProduto, addItem, 
  update, cabecalhoTabela, acoesTabela, editarItem, removerItem, conta, updateFieldParcela, mudarParcelas, recalcularParcela,
  verificaDataParcela, formasPagamento, contasFinanceira, updateFieldConta, imprimirBoleto, marcaParcelas, marcaParcelasContaFin }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      {dados.pedcomp_id !== '' &&
        <React.Fragment>
          <Grid item md={2} xs={12} sm={6}>
            <TextField
              className="input" 
              label="Num. Pedido" 
              variant="outlined" 
              size="small" 
              name="pedcomp_numero" 
              value={dados.pedcomp_numero}
              InputLabelProps={{ shrink: true }}
              disabled
            />
          </Grid>
          <Grid item md={2} xs={12} sm={6}>
            <TextField
              className="input" 
              type="date" 
              label="Data da Compra" 
              variant="outlined" 
              size="small" 
              name="pedcomp_data_compra" 
              value={dados.pedcomp_data_compra}
              InputLabelProps={{ shrink: true }} 
              disabled
            />
          </Grid>
          <Grid item md={3} xs={12} sm={6}>
            <TextField
              className="input"
              label="Situação" 
              variant="outlined" 
              size="small" 
              name="pedcomp_situacao" 
              value={formataSituacao(dados.pedcomp_situacao)}
              InputLabelProps={{ shrink: true }} 
              disabled
            />
          </Grid>
        </React.Fragment>
      }
      <Grid item md={3} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Unidade*"
          variant="outlined"
          className="input"
          size="small" 
          fullWidth
          SelectProps={{
            native: true,
          }}
          name="pedcomp_unidade_id"
          value={dados.pedcomp_unidade_id}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
          disabled={(dados.pedcomp_situacao === "AGUARDANDO_PAGAMENTO" || dados.pedcomp_situacao === 'FINALIZADO' || dados.pedcomp_situacao === 'CANCELADO')}
        >
          <option value=""> Selecionar Unidade </option>
          {unidades.sort((a, b) => (a.unin_numero > b.unin_numero) ? 1 : ((b.unin_numero > a.unin_numero) ? -1 : 0)).map((unidade, key) => {
            return (
              <option key={key} value={unidade.unin_id}>{unidade.unin_numero} - {unidade.unin_descricao} </option>
            )
          })}
        </TextField>
      </Grid>
    </Grid>
    <hr className="mg_top_10"/>
    {(dados.pedcomp_unidade_id !== '') &&
      <React.Fragment>
        <Grid container direction="row" spacing={1}>
          <Grid item md={12} xs={12} sm={12}>
            <h3>Produtos:</h3>
          </Grid>
        </Grid>
        {(dados.pedcomp_situacao !== 'FINALIZADO' && dados.pedcomp_situacao !== 'CANCELADO') &&
          <Grid container direction="row" spacing={1}>
            <Grid item md={1} xs={12} sm={2}>
              <Grid container direction="row" spacing={1}>
                <Grid item md={12} xs={12} sm={12}>
                  <div style={{width: '100%', border: '1px dashed #c0c0c0', height: '110px', textAlign: 'center'}}>
                    {produto_selecionado.logo &&
                      <img src={produto_selecionado.logo} alt="Icone Produto" />
                    }
                    {!produto_selecionado.logo && 
                      <p style={{marginTop:'40px'}}>LOGO</p>
                    }
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={11} xs={12} sm={10}>
              <Grid container direction="row" spacing={1}>
                <Grid item md={4} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Produto*"
                    variant="outlined"
                    className="input"
                    size="small" 
                    fullWidth
                    SelectProps={{
                      native: true,
                    }}
                    name="prod_id"
                    value={produto_selecionado.prod_id}
                    onChangeCapture={(e) => updateFieldProduto(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value=""> Selecionar Produto </option>
                    {produtos.sort((a, b) => (a.prod_descricao > b.prod_descricao) ? 1 : ((b.prod_descricao > a.prod_descricao) ? -1 : 0)).map(produto => {
                      return (
                        <option value={produto.prod_id}>{produto.prod_descricao} </option>
                      )
                    })}
                  </TextField>
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField 
                    className="input" 
                    label="Valor Unit.*" 
                    variant="outlined" 
                    name="valor"
                    size="small"
                    value={produto_selecionado.valor} 
                    onChange={(e) => updateFieldProduto(e)} 
                    InputLabelProps={{ shrink: true }} 
                    InputProps={{ inputComponent: CurrencyInput }} 
                  />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField 
                    className="input" 
                    label="Desc./Acresc. Unit.*" 
                    variant="outlined" 
                    name="desconto"
                    size="small"
                    value={produto_selecionado.desconto} 
                    onChange={(e) => updateFieldProduto(e)} 
                    InputLabelProps={{ shrink: true }} 
                    InputProps={{ inputComponent: CurrencyInput }} 
                  />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField 
                    className="input" 
                    type="number"
                    label="Quantidade*" 
                    variant="outlined" 
                    name="qtd"
                    size="small"
                    value={produto_selecionado.qtd} 
                    onChange={(e) => updateFieldProduto(e)} 
                    InputLabelProps={{ shrink: true }} 
                  />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={update ? <CreateIcon /> : <AddIcon />} onClick={e => addItem(e)}>{update ? 'Aterar' : 'Adicionar'}</Button>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} style={{marginTop: 5}}>
                <Grid item md={12} xs={12} sm={12}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Observação Produto"
                    className="input"
                    multiline
                    rows={2}
                    size="small"
                    variant="outlined"
                    name="observaocao"
                    value={produto_selecionado.observaocao}
                    onChange={(e) => updateFieldProduto(e)}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        <Grid container spacing={1} direction="row">
          <Grid item md={12} xs={12}>
            <Table
              editar={e => editarItem(e)}
              remove={e => removerItem(e)}
              headCell={cabecalhoTabela}
              acoes={acoesTabela}
              rows={dados.itens}
              noRemove={false}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={1} style={{marginTop: 5}}>
          <Grid item md={12} xs={12} sm={12}>
            <TextField
              id="outlined-multiline-static"
              label="Observação Pedido"
              className="input"
              multiline
              rows={2}
              size="small"
              variant="outlined"
              name="pedcomp_observacao"
              value={dados.pedcomp_observacao}
              onChange={(e) => updateField(e)}
              InputLabelProps={{ shrink: true }}
              disabled={(dados.pedcomp_situacao === 'FINALIZADO' || dados.pedcomp_situacao === 'CANCELADO')}
            />
          </Grid>
        </Grid>
        <hr className="mg_top_10"/>
        <Grid container direction="row" spacing={1}>
          <Grid item md={12} xs={12} sm={12}>
            <h3>Forma de Pagamento: </h3>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={2} xs={12} sm={6}>
            <TextField
              className="input" 
              label="Valor Total da Venda" 
              variant="outlined" 
              size="small" 
              name="valor_total" 
              value={dados.valor_total}
              InputLabelProps={{ shrink: true }}
              disabled
            />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={12} xs={12} sm={12}>
            <div className='scroll'>
              <table className="lista sempadding borderTable">
                <thead>
                  <tr className="titulos acompanha">
                    <th>Parcelas</th>
                    <th>Valor*</th>
                    <th>Vencimento*</th>
                    <th>Forma de Pagamento*</th>
                    <th>Conta Financeira</th>
                    <th>Pagamento</th>
                  </tr>
                </thead>
                <tbody>
                  {conta.parcelas.sort((a, b) => (a.index > b.index) ? 1 : (b.index > a.index) ? -1 : 0).map(parcela => {
                    return (
                      <tr key={parcela.index}>
                        <td>
                          <TextField className="inputParcela" variant="outlined" size="small" name="index" value={parcela.index} InputLabelProps={{ shrink: true }} disabled />
                          <span className="separacaoParcelas">/</span>
                          <TextField className="inputParcela" variant="outlined" size="small" name="qtd_parcelas" value={conta.qtd_parcelas} onChange={(e) => updateFieldConta(e)} onBlur={(e) => mudarParcelas(e)} InputLabelProps={{ shrink: true }} disabled={(dados.pedcomp_situacao === 'FINALIZADO' || dados.pedcomp_situacao === 'CANCELADO')}/>
                        </td>
                        <td>
                          <TextField className="inputValor" variant="outlined" size="small" name="parcont_valorparcela" value={parcela.parcont_valorparcela} onBlur={(e) => recalcularParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} disabled={(dados.pedcomp_situacao === 'FINALIZADO' || dados.pedcomp_situacao === 'CANCELADO')}/>
                        </td>
                        <td>
                          <TextField type="date" className="inputVencimento" variant="outlined" size="small" name="parcont_datavencimento" value={parcela.parcont_datavencimento} onBlur={(e) => verificaDataParcela(e, parcela.index)} onChange={(e) => updateFieldParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} disabled={(dados.pedcomp_situacao === 'FINALIZADO' || dados.pedcomp_situacao === 'CANCELADO')}/>
                        </td>
                        <td>
                          <TextField
                            id="standard-select-currency"
                            select
                            variant="outlined"
                            className="inputFormPagamento"
                            fullWidth
                            size="small"
                            SelectProps={{
                              native: true,
                            }}
                            name="parcont_formapagto_id"
                            value={parcela.parcont_formapagto_id}
                            onChangeCapture={(e) => updateFieldParcela(e, parcela.index)}
                            onBlur={(e) => marcaParcelas(e, parcela.index)}
                            InputLabelProps={{ shrink: true }}
                            disabled={(dados.pedcomp_situacao === 'FINALIZADO' || dados.pedcomp_situacao === 'CANCELADO')}
                          >
                            <option value="">--Escolha--</option>
                            {formasPagamento.map(forma => {
                              return (
                                <option key={forma.formpag_id} value={forma.formpag_id}>{forma.formpag_descricao}</option>
                              )
                            })}
                          </TextField>
                        </td>
                        <td>
                          <TextField
                            id="standard-select-currency"
                            select
                            variant="outlined"
                            className="inputConta"
                            fullWidth
                            size="small"
                            SelectProps={{
                              native: true,
                            }}
                            name="contfin_id"
                            value={parcela.contfin_id}
                            onChangeCapture={(e) => updateFieldParcela(e, parcela.index)}
                            onBlur={(e) => marcaParcelasContaFin(e, parcela.index)}
                            InputLabelProps={{ shrink: true }}
                            disabled={(parcela.parcont_formapagto_id === '' || dados.pedcomp_situacao === 'FINALIZADO' || dados.pedcomp_situacao === 'CANCELADO')}
                          >
                            <option value="">--Escolha--</option>
                            {contasFinanceira.filter(param => param.contfin_tipo_conta === (parseInt(parcela.parcont_formapagto_id) === 1 ? 'COFRE': 'BANCO')).map(conta => {
                              return (
                                <option key={conta.contfin_id} value={conta.contfin_id}>{conta.contfin_descricao}</option>
                              )
                            })}
                          </TextField>
                        </td>
                        <td style={{padding: 10}}>
                          {(parcela.parceiro_id && (parcela.parcont_situacao === 'A VENCER' || parcela.parcont_situacao === 'VENCIDO')) &&
                            <Tooltip title={'Visualizar Pagamento'} aria-label="add">
                              <PrintIcon style={{fontSize: 30, cursor: 'pointer'}} color="primary" onClick={() => imprimirBoleto(parcela)}/>
                            </Tooltip>
                          }
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </Grid>
        </Grid>
        <hr className="mg_top_10"/>
      </React.Fragment>
    }
  </React.Fragment>
)

export default FormNotificacao