import React, { Component } from 'react'
import './Agenda.scss'
import './../../../main/ultil.scss'
import { withStyles } from "@material-ui/core/styles";
import { Grid } from '@material-ui/core'
import Alert from './../../../components/templates/Alert'
import FormAgendaAula from '../../../components/forms/FormAgendaAula'
import ModalErro from '../../../components/modals/Erro'
import ModalConfirmacaoAgenda from '../../../components/modals/ConfirmacaoAgenda'
import ModalRemoveAlunoAgenda from '../../../components/modals/RemoveAlunoAgenda'
import ModalAulasAbertas from '../../../components/modals/AulasAbertas'
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import axios from 'axios'
import moment from 'moment';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import FirstPageIcon from '@material-ui/icons/FirstPage';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
}))(Tooltip);

const initalState = {
  agenda: {
    _id: "",
    ageaula_data: "",
    ageaula_hora: "",
    ageaula_aula_feita: false,
    ageaula_nota_aula: 0,
    ageaula_professor_id: "",
    ageaula_aula_id: "",
    ageaula_unin_id: "",
    alunos: []
  },
  agendas: {
    list: []
  },
  agendasFiltrada: {
    list: []
  },
  agendasFiltradaAula: {
    list: []
  },
  avaliacao: {
    avalaula_descricao: "",
    avalaula_nota: 0
  },
  aulas: {
    list: []
  },
  feriados: {
    list: []
  },
  feriadosFiltrado: {
    list: []
  },
  ausencias: {
    list: []
  },
  professor: {
    prof_id: "",
    pesf_nome: ""
  },
  professores: {
    list: []
  },
  unidade: {
    unin_id: "",
    unin_numero: "",
    unin_descricao: "",
  },
  unidades: {
    list: []
  },
  aula: {
    aula_id: "",
    aula_nome: "",
    aula_tipo: "",
    aula_tipo_horario: "",
    bok_nome: ""
  },
  curso: {
    curso_id: "",
    curso_nome: "",
  },
  cursos: {
    list: []
  },
  professoresFiltrado: {
    list: []
  },
  aluno: {
    pesf_nome: "",
    aluno_id: "",
    aluno_matricula: "",
    contrato: {
      curso: {
        curso_nome: ""
      }
    }
  },
  alunos: {
    list: []
  },
  alunosFiltrado: {
    list: []
  },
  aulasFiltrada: {
    list: []
  },
  modalErro: false,
  modalCancelamento: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loading: true,
  cabecalhoTabela: [
    { id: 'avalaula_descricao', numeric: false, disablePadding: true, label: 'Descrição Avaliação' },
    { id: 'avalaula_nota', numeric: false, disablePadding: false, label: 'Nota Avaliação' },
  ],
  acoesTabela: ['editar', 'remove'],
  update: false,
  datas: [],
  disponibilidades: [],
  professoresDisp: [],
  modalConfirmacao: false,
  dadosModalConfirmacao: {
    agenda: {
      ageaula_data_hora: '',
      aula: {
        aula_nome: ''
      },
      professor: {
        funcionario: {
          fisica: {
            pesf_nome: ''
          }
        }
      }
    },
    professores: [],
    data: {
      diaSemana: "",
      dia: "",
      mes: "",
      ano: ""
    },
    hora: "",
    aula: {
      aula_id: "",
      aula_nome: "",
      aula_cor: "",
      aula_cor_texto: ""
    },
  },
  unin_tipo: '',
  aberto: false,
  agendasFiltradaAluno: {
    list: []
  },
  limiteAulas: {
    list: []
  },
  periodo: {
    data_inicial: "",
    data_final: ''
  },
  pulaCelula: false,
  disabledSalvar: false,
  ultimasAulas: {
    agenda: {
      ageaula_data_hora: '',
      aula: {
        aula_nome: ''
      },
      professor: {
        funcionario: {
          fisica: {
            pesf_nome: ''
          }
        }
      }
    }
  },
  parcelasVencidas: [],
  openFinPendente: false,
  loadingUltimaAula: true,
  loadingFinanceiroVencido: true,
  loadingAgenda: true,
  liberaAula: false,
  verificaCodigo:{
    liberaAula: false,
    qtdAulasFaltante: 0,
    possuiCodigo: false
  },
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  openModalAulasAbertas: false,
  aulas_abertas: {
    list: []
  },
  confunin_perm_agenda_sem_cod: false,
  filtro: {
    aula_id: '',
    aluno_id: '',
    unin_id: '',
    data_inicial: moment().format('YYYY-MM-DD'),
    data_final: moment().add(9, 'days').format('YYYY-MM-DD')
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
}

export default class Agenda extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async componentDidMount() {
    const aluno = this.props.aluno;
  
    this.setState({
      aluno: {
        pesf_nome: aluno.pessoaFisica.nome,
        aluno_id: aluno._id,
        aluno_matricula: aluno.aluno_matricula,
        contrato: {
          contm_data_fim_vigencia: aluno.contrato.contm_data_fim_vigencia,
          contm_data_inicio_vigencia: aluno.contrato.contm_data_inicio_vigencia,
          curso: {
            curso_nome: aluno.contrato.curso.curso_nome
          }
        },
        contratos_ativos: aluno.contratos_ativos
      },
      aula: {
        aula_id: "",
        aula_nome: "",
        aula_cor: "",
        aula_cor_texto: "",
        aula_tipo_horario: ""
      },
      unidade: {
        unin_id: aluno.contrato.unidade.unin_id,
        unin_numero: aluno.contrato.unidade.unin_numero,
        unin_descricao: aluno.contrato.unidade.unin_descricao,
      },
      professor: {
        prof_id: "",
        pesf_nome: ""
      },
      disabledSalvar: false,
      loading: true
    });
  
    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'));
    const dados = {
      unidadesnegocio: acesso_atual.map(acesso => acesso.unin_id)
    };
  
    const urls = [
      axios.post(`${process.env.REACT_APP_API_URL}/feriados/list`, dados, this.getToken()),
      axios.get(`${process.env.REACT_APP_API_URL}/ausenciaTemporaria/${aluno.aluno_matricula}`, this.getToken()),
      axios.get(`${process.env.REACT_APP_API_URL}/aula/codigo/${aluno.contrato.contm_curso_id}/${aluno._id}`, this.getToken()),
      axios.get(`${process.env.REACT_APP_API_URL}/configuracaoUnidade/byUnidade/${aluno.aluno_unin_id}`, this.getToken()),
      axios.get(`${process.env.REACT_APP_API_URL}/parcelaConta/byAluno/vencidas/${aluno._id}/${aluno.contrato.contm_unin_id}`, this.getToken()),
      axios.get(`${process.env.REACT_APP_API_URL}/agendaAula/ultimaAula/byAluno/${aluno._id}/${aluno.aluno_unin_id}`, this.getToken())
    ];
  
    const results = await Promise.allSettled(urls);
  
    const [feriadosRes, ausenciasRes, aulasRes, configRes, parcelasRes, ultimasAulasRes] = results;
  
    if (feriadosRes.status === "fulfilled") {
      const feriados = feriadosRes.value.data.map(feriado => {
        const dataFeriado = moment(feriado.fer_data, moment.defaultFormat).toDate();
        dataFeriado.setMinutes(dataFeriado.getMinutes() + dataFeriado.getTimezoneOffset() + 60);
        return {
          _id: feriado.fer_id,
          fer_descricao: feriado.fer_descricao,
          fer_tipo: feriado.fer_tipo,
          fer_data: moment(dataFeriado).format('DD/MM/YYYY'),
          unidades: feriado.unidades.map(unidade => ({
            unin_numero: unidade.unin_numero,
            unin_descricao: unidade.unin_descricao,
            unin_numero_descricao: `${unidade.unin_numero} - ${unidade.unin_descricao}`
          }))
        };
      });
      this.setState({ feriados: { list: feriados } });
    } else {
      this.handleError(feriadosRes.reason);
    }
  
    if (ausenciasRes.status === "fulfilled") {
      this.setState({ ausencias: { list: ausenciasRes.value.data.filter(param => param.austemp_ativo === true) } });
    } else {
      this.handleError(ausenciasRes.reason);
    }
  
    if (aulasRes.status === "fulfilled") {
      const data = aulasRes.value.data;
      this.updateFieldAula({}, data[0], true);
      this.setState({
        aulas: { list: data },
        aulasFiltrada: { list: data }
      });
    } else {
      this.handleError(aulasRes.reason);
    }

    this.setState({ loading: false });
  
    if (configRes.status === "fulfilled") {
      const configuracao = configRes.value.data;
      this.setState({ confunin_perm_agenda_sem_cod: configuracao.confunin_perm_agenda_sem_cod });
  
      const { aula, aluno } = this.state;
      try {
        const { data: verificaCodigo } = await axios.get(
          `${process.env.REACT_APP_API_URL}/codigoMaterial/verificaCodigo/${aula.bok_id}/${aluno.aluno_id}`,
          this.getToken()
        );
  
        this.setState({
          verificaCodigo: {
            liberaAula: configuracao.confunin_perm_agenda_sem_cod ? true : verificaCodigo.liberaAula,
            qtdAulasFaltante: 0,
            possuiCodigo: configuracao.confunin_perm_agenda_sem_cod ? true : verificaCodigo.possuiCodigo,
          }
        });
      } catch (error) {
        this.handleError(error);
      }
    } else {
      this.handleError(configRes.reason);
    }
  
    if (parcelasRes.status === "fulfilled") {
      this.setState({
        loadingFinanceiroVencido: false,
        parcelasVencidas: parcelasRes.value.data
      });
    } else {
      this.handleError(parcelasRes.reason);
    }
  
    if (ultimasAulasRes.status === "fulfilled") {
      this.setState({
        ultimasAulas: ultimasAulasRes.value.data,
        loadingUltimaAula: false
      });
    } else {
      this.handleError(ultimasAulasRes.reason);
    }
  }
  
  // Função para tratar erros
  handleError(error) {
    this.setState({
      alerta: {
        open: true,
        autoHideDuration: 5000,
        severity: 'error',
        message: error.response?.data?.message || "Erro inesperado"
      }
    });
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  handleCloseModalCancelamento() {
    this.setState({
      modalCancelamento: !this.state.modalCancelamento
    })
  }

  handleCloseModal() {
    this.setState({
      modalConfirmacao: !this.state.modalConfirmacao
    })
  }

  backPage(timeout = 1000) {
    // const self = this
    setTimeout(() => {
      window.location.reload()
    }, timeout)
    this.setState({
      produto: initalState.produto
    })
  }

  async updateFieldAula(event, value, atualizaData) {
    if (!value || !value.aula_id) {
      return false
    }
    
    this.setState({
      loadingAgenda: true
    })

    const { unidade, aluno, agenda } = this.state

    localStorage.setItem('aula_selecionada_agenda', JSON.stringify(value));

    agenda.ageaula_aula_id = value.aula_id
    agenda.ageaula_unin_id = unidade.unin_id

    this.setState({
      aula: value,
      agenda
    })

    try {
      let filtro = {
        aula_id: value.aula_id,
        aluno_id: aluno.aluno_id,
        unin_id: unidade.unin_id,
        data_inicial: this.state.filtro.data_inicial,
        data_final: this.state.filtro.data_final
      }

      if(atualizaData){
        filtro.data_inicial = moment().format('YYYY-MM-DD')
        filtro.data_final = moment().add(9, 'days').format('YYYY-MM-DD')

        this.setState({
          filtro
        })
      }

      const { data: datas } = await axios.post(`${process.env.REACT_APP_API_URL}/agendaAula/montaAgenda`, filtro, this.getToken())

      this.setState({ datas })

    } catch ({response}) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        }
      })
      return
    }

    this.setState({
      loadingAgenda: false
    })

  }

  updateFieldFuncionario(event, value) {
    const { agenda } = this.state

    if (!value) {
      agenda.ageaula_professor_id = ''
      
      agenda.professor = {
        prof_id: '',
        funcionario: {
          fisica: {
            pesf_nome: ''
          }
        }
      }

      this.setState({
        agenda,
        professor: {
          prof_id: '',
          pesf_nome: ''
        }
      })

      return false
      
    }else{
   
      agenda.ageaula_professor_id = value.prof_id
      
      agenda.professor = {
        prof_id: value.prof_id,
        funcionario: {
          fisica: {
            pesf_nome: value.pesf_nome
          }
        }
      }

      this.setState({
        agenda,
        professor: {
          prof_id: value.prof_id,
          pesf_nome: value.pesf_nome
        }
      })
    }
   
    
    
  }

  openCancelarAula(agenda) {

    this.setState({
      dadosModalConfirmacao: {
        agenda,
        professores: [],
        data: {
          diaSemana: "",
          dia: "",
          mes: "",
          ano: ""
        },
        hora: "",
        aula: {
          aula_id: "",
          aula_nome: "",
          aula_cor: "",
          aula_cor_texto: ""
        },
      },
      modalCancelamento: true
    })
  }

  async openDiaAula(hora, data, aberto, agendaMarcada, disponiveis) {
    try {
      const {aula, aluno} = this.state

      const { data: verificaCodigo } = await axios.get(`${process.env.REACT_APP_API_URL}/codigoMaterial/verificaCodigo/${aula.bok_id}/${aluno.aluno_id}`, this.getToken())

      if (this.state.confunin_perm_agenda_sem_cod || verificaCodigo.liberaAula) {

        const professoresDisp = []

        disponiveis.forEach(prof => {
          professoresDisp.push({
            prof_id: prof.prof_id,
            pesf_nome: prof.funcionario.fisica.pesf_nome
          })
        });

        const agenda = { ...this.state.agenda }
        agenda.ageaula_hora = hora
        agenda.ageaula_data = `${data.ano}-${data.mes}-${data.dia}`

        let professor = {}

        if (aberto) {
          professor = {
            prof_id: agendaMarcada.professor.prof_id,
            pesf_nome: agendaMarcada.professor.funcionario.fisica.pesf_nome
          }

          agenda.ageaula_professor_id = agendaMarcada.professor.prof_id
          agenda._id = agendaMarcada.ageaula_id
          agenda.professor = professor
          agenda.alunos = agendaMarcada.alunos
          agenda.unidade = agendaMarcada.unidade
          agenda.aula = agendaMarcada.aula
          agenda.alunos = agendaMarcada.alunos
        }else{
          const aluno = this.props.aluno
          const {aula} = this.state

          agenda.alunos = [{
            aluno_id: aluno._id,
            aluno_matricula: aluno.aluno_matricula,
            fisica:{
              pesf_nome: aluno.pessoaFisica.nome
            }
          }]

          agenda.unidade = {
            unin_descricao: aluno.contrato.unidade.unin_descricao,
            unin_id: aluno.contrato.unidade.unin_id,
            unin_numero: aluno.contrato.unidade.unin_numero
          }

          agenda.aula = {
            aula_aluno_padrao: aula.aula_aluno_padrao,
            aula_duracao: aula.aula_duracao,
            aula_id: aula.aula_id,
            aula_nome: aula.aula_nome,
            aula_tipo_horario: aula.aula_tipo_horario,
            limite_aula: {
              limauluni_limite: aula.aula_aluno_padrao
            }
          }
        }

        const { data: aulas_abertas } = await axios.get(`${process.env.REACT_APP_API_URL}/aluno/aulasAbertas/${aluno.aluno_id}`, this.getToken())

        this.setState({
          agenda,
          professoresDisp,
          modalConfirmacao: true,
          dadosModalConfirmacao: {
            agenda: {
              ageaula_data_hora: '',
              aula: {
                aula_nome: ''
              },
              professor: {
                funcionario: {
                  fisica: {
                    pesf_nome: ''
                  }
                }
              }
            },
            professores: professoresDisp,
            data,
            hora,
            aula: agenda.aula,
            aberto
          },
          professor,
          aberto
        })

        if(aulas_abertas.length > 0){
          this.setState({
            openModalAulasAbertas: true,
            aulas_abertas: {
              list: aulas_abertas
            }
          })
        }

      } else {
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message: 'Esse aluno já marcou 2 aulas sem o book'
          }
        })
        return
      }

    } catch ({ response }) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        }
      })
      return
    }
  }

  renderTooltip(agenda) {

    const rows = []

    rows.push(<b key='Professor'>Prof: {agenda.professor.funcionario.fisica.pesf_nome} </b>)
    rows.push(<hr key='hr1' />)

    agenda.alunos.forEach(aluno => {
      rows.push(<span key={aluno.aluno_id}>{aluno.aluno_matricula} - {aluno.fisica.pesf_nome} </span>)
      rows.push(<br />)
    });

    rows.push(<br />)
    rows.push(<hr key='hr2' />)


    let usuario_agendador = ''
    const aluno_id = this.props.aluno._id
  
    if(agenda.alunosagenda){
      for (const aluno_agenda of agenda.alunosagenda) {
        if(aluno_id === aluno_agenda.aluaula_aluno_id){
          usuario_agendador = aluno_agenda.usuario ? aluno_agenda.usuario.pessoa.fisica.pesf_nome : ''
        }
      }
    }

    rows.push(<b key='Usuario'>Usu.: {usuario_agendador} </b>)

    return rows
  }

  voltar() {
    this.setState({
      modalConfirmacao: false
    })
  }

  voltarCancelamento() {
    this.setState({
      modalCancelamento: false
    })
  }

  validateForm() {
    const { agenda, aluno } = this.state

    if (!agenda.ageaula_data) return false
    if (!agenda.ageaula_hora) return false
    if (!agenda.ageaula_professor_id) return false
    if (agenda.ageaula_professor_id === '') return false
    if (!agenda.ageaula_aula_id) return false
    if (!agenda.ageaula_unin_id) return false
    if (!aluno.aluno_id) return false

    return true
  }

  async confirmarCancelamento(agenda) {
    const { aluno, datas } = this.state

    this.setState({ disabledSalvar: true })

    const agendaAluno = agenda.alunos.filter(param => param.aluno_id === aluno.aluno_id && !param.AlunoAgenda.aluaula_status)[0]

    try {
      
      await axios.delete(`${process.env.REACT_APP_API_URL}/agendaAula/removeAluno/${agendaAluno.AlunoAgenda.aluaula_id}`, this.getToken())

      const indexData = datas.findIndex(param => param.dataFormatada === moment(agenda.ageaula_data_hora).format('DD/MM/YYYY'))

      const indexHorario = datas[indexData].horarios.findIndex(param => param.hora === moment(agenda.ageaula_data_hora).format('HH:mm'))

      if(agenda.alunos.length === 1){
        
        if(agenda.aula.aula_tipo_horario !== 'FIXO'){
          delete datas[indexData].horarios[indexHorario].aulaAberta
        }else{
          let aulaAberta = datas[indexData].horarios[indexHorario].aulaAluno
        
          const indexAluno = aulaAberta.alunos.findIndex(param => param.aluno_id === aluno.aluno_id)

          aulaAberta.alunos.splice(indexAluno, 1)

          datas[indexData].horarios[indexHorario].aulaAberta = {
            ...aulaAberta
          }
        }

        delete datas[indexData].horarios[indexHorario].aulaAluno

        const indexProf = datas[indexData].horarios[indexHorario].professoresDisp.findIndex(param => param.prof_id === agenda.professor.prof_id)

        if(indexProf === -1){
          datas[indexData].horarios[indexHorario].professoresDisp.push({...agenda.professor})
        }

      }else{

        let aulaAberta = datas[indexData].horarios[indexHorario].aulaAluno
        
        const indexAluno = aulaAberta.alunos.findIndex(param => param.aluno_id === aluno.aluno_id)

        aulaAberta.alunos.splice(indexAluno, 1)

        datas[indexData].horarios[indexHorario].aulaAberta = {
          ...aulaAberta
        }
        
        delete datas[indexData].horarios[indexHorario].aulaAluno
      }

      const verificaAtual = {...this.state.verificaCodigo}

      this.setState({
        modalCancelamento: false,
        datas,
        verificaCodigo:{
          liberaAula: this.state.confunin_perm_agenda_sem_cod ? true : ((verificaAtual.qtdAulasFaltante + 1) > 0 ? true : false),
          qtdAulasFaltante: this.state.verificaCodigo.qtdAulasFaltante + 1,
          possuiCodigo: this.state.confunin_perm_agenda_sem_cod ? true : verificaAtual.possuiCodigo
        },
        disabledSalvar: false
      })

      await this.updateFieldAula({}, this.state.aula, false)

    } catch ({response}) {
      this.setState({ disabledSalvar: false })
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        }
      })
      return
    }

  }

  montaViewAula(data, horario) {

    const { aula } = this.state

    const ausencias = this.state.ausencias.list

    const indexAusencia = ausencias.findIndex(param => new Date(param.austemp_data_inicial).getTime() <= new Date(data.dataSemFormato).getTime() && new Date(param.austemp_data_final).getTime() >= new Date(data.dataSemFormato).getTime())

    if (indexAusencia !== -1) {
      return (
        <div>
          <p>AT</p>
        </div>
      )
    }

    if (horario.aulaAluno) {
      return (
        <LightTooltip
          title={this.renderTooltip(horario.aulaAluno)}
        >
          <div className="aula_marcada" onClick={() => this.openCancelarAula(horario.aulaAluno)}>
            <p>{horario.aulaAluno.aula.aula_nome}</p>
            {!horario.aulaAluno.aula.limite_aula &&
              <p>{horario.aulaAluno.alunos.length} / {horario.aulaAluno.aula.aula_aluno_padrao}</p>
            }
            {horario.aulaAluno.aula.limite_aula &&
              <p>{horario.aulaAluno.alunos.length} / {horario.aulaAluno.aula.limite_aula.limauluni_limite}</p>
            }
          </div>
        </LightTooltip>

      )
    }

    if (horario.aulaAberta) {
      let limiteAlunos = 0

      if (!horario.aulaAberta.aula.limite_aula) {
        limiteAlunos = horario.aulaAberta.aula.aula_aluno_padrao
      }
      if (horario.aulaAberta.aula.limite_aula) {
        limiteAlunos = horario.aulaAberta.aula.limite_aula.limauluni_limite
      }

      if (horario.aulaAberta.alunos.length >= limiteAlunos) {
        if (aula.aula_tipo_horario === 'FIXO') {
          return (
            <div className="aula_lotada">
              <p> {horario.aulaAberta.aula.aula_nome}</p>
              {!horario.aulaAberta.aula.limite_aula &&
                <p>{horario.aulaAberta.alunos.length} / {horario.aulaAberta.aula.aula_aluno_padrao}</p>
              }
              {horario.aulaAberta.aula.limite_aula &&
                <p>{horario.aulaAberta.alunos.length} / {horario.aulaAberta.aula.limite_aula.limauluni_limite}</p>
              }
            </div>
          )
        }
      }
    }

    if (horario.aulaAberta) {
      return (
        <div className="aula_aberta" onClick={() => this.openDiaAula(horario.hora, data, true, horario.aulaAberta, horario.professoresDisp)}>
          <p> {horario.aulaAberta.aula.aula_nome}</p>
          {!horario.aulaAberta.aula.limite_aula &&
            <p>{horario.aulaAberta.alunos.length} / {horario.aulaAberta.aula.aula_aluno_padrao}</p>
          }
          {horario.aulaAberta.aula.limite_aula &&
            <p>{horario.aulaAberta.alunos.length} / {horario.aulaAberta.aula.limite_aula.limauluni_limite}</p>
          }
        </div>
      )
    }

    if (aula.aula_tipo_horario !== 'FIXO') {
      if (horario.professoresDisp.length > 0) {
        return (
          <div className="aula_disp" onClick={() => this.openDiaAula(horario.hora, data, false, {}, horario.professoresDisp)}>
            <p>
              {horario.hora.replace(":00", "h")}
            </p>
          </div>
        )
      }
    }

    return (
      <div>
        <p>-</p>
      </div>
    )
  }

  async salvar() {

    this.setState({
      loadingSalvar: true,
      disabledSalvar: true
    })

    const { agenda, aluno, aberto, datas } = this.state

    const pessoa_usuario = JSON.parse(localStorage.getItem('pessoa'))
    const usuario = JSON.parse(localStorage.getItem('usuario'))

    if (!aberto) {
      if (!this.validateForm()) {
        this.setState({ disabledSalvar: false })
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message: 'Preencha os campos obrigatorios (*).'
          }
        })
        return
      }

      const dados = {
        ageaula_data_hora: agenda.ageaula_data + " " + agenda.ageaula_hora,
        ageaula_professor_id: agenda.ageaula_professor_id,
        ageaula_unin_id: agenda.ageaula_unin_id,
        ageaula_aula_id: agenda.ageaula_aula_id,
        ageaula_status: 'ABERTO',
        alunos: [
          {
            aluno_id: aluno.aluno_id
          }
        ]
      }

      try {

        const {data} = await axios.post(`${process.env.REACT_APP_API_URL}/agendaAula`, dados, this.getToken())
        
        const indexData = datas.findIndex(param => param.dataFormatada === moment(dados.ageaula_data_hora).format('DD/MM/YYYY'))

        const indexHorario = datas[indexData].horarios.findIndex(param => param.hora === moment(dados.ageaula_data_hora).format('HH:mm'))

        datas[indexData].horarios[indexHorario].aulaAluno = {
          ageaula_id: data.ageaula_id,
          ageaula_data_hora: dados.ageaula_data_hora,
          ageaula_duracao_aula: data.ageaula_duracao_aula,
          alunos: data.alunos.map(aluno => {
            return {
              AlunoAgenda: aluno.AlunoAgenda,
              aluno_id: aluno.aluno_id,
              aluno_matricula: aluno.aluno_matricula,
              fisica: {
                pesf_nome: aluno.fisica.pesf_nome
              }
            }
          }),
          aula: {
            aula_aluno_padrao: agenda.aula.aula_aluno_padrao,
            aula_duracao: agenda.aula.aula_duracao,
            aula_id: dados.ageaula_aula_id,
            aula_nome: agenda.aula.aula_nome,
            aula_tipo_horario: agenda.aula.aula_tipo_horario,
            limite_aula: {
              limauluni_limite: agenda.aula.limite_aula ? agenda.aula.limite_aula.limauluni_limite : agenda.aula.aula_aluno_padrao
            }
          },
          professor: {
            prof_id: dados.ageaula_professor_id,
            funcionario: {
              fisica: {
                pesf_nome: agenda.professor.funcionario.fisica.pesf_nome
              }
            }
          },
          alunosagenda:[{
            aluaula_aluno_id: aluno.aluno_id,
            usuario:{
              usu_id: usuario.usu_id,
              pessoa:{
                pes_id: pessoa_usuario.pessoa.pes_id,
                fisica: {
                  pesf_nome: pessoa_usuario.fisica.pesf_nome
                }
              }
            },
          }],
          unidade:{
            unin_descricao: agenda.unin_descricao,
            unin_id: dados.ageaula_unin_id,
            unin_numero: agenda.unin_numero
          }
        }

        this.setState({
          disabledSalvar: false,
          modalConfirmacao: false,
          datas,
          liberaAula: this.state.confunin_perm_agenda_sem_cod ? true : ((this.state.verificaCodigo.qtdAulasFaltante + 1) > 0 ? true : false),
          verificaCodigo:{
            liberaAula: this.state.confunin_perm_agenda_sem_cod ? true : ((this.state.verificaCodigo.qtdAulasFaltante + 1) > 0 ? true : false),
            qtdAulasFaltante: this.state.verificaCodigo.qtdAulasFaltante - 1,
            possuiCodigo: this.state.confunin_perm_agenda_sem_cod ? true : this.state.verificaCodigo.possuiCodigo
          }
        })


      } catch ({response}) {
        this.setState({ disabledSalvar: false })
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'error',
            message: response.data.message
          }
        })
      }
    } else {
      if (!this.validateForm()) {
        this.setState({ disabledSalvar: false })
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'warning',
            message: "Preencha os campos obrigatorios (*)."
          }
        })
        return
      }

      let dados = {
        ageaula_data_hora: moment(agenda.ageaula_data + " " + agenda.ageaula_hora).format(),
        ageaula_status: 'ABERTO',
        ageaula_professor_id: agenda.ageaula_professor_id,
        ageaula_unin_id: agenda.ageaula_unin_id,
        ageaula_aula_id: agenda.aula.aula_id,
        alunos: [...agenda.alunos]
      }

      dados.alunos.push({
        aluno_id: aluno.aluno_id,
        aluno_matricula: aluno.aluno_matricula,
        fisica: {
          pesf_nome: aluno.pesf_nome
        }
      })
      
      try {
        
        const {data} = await axios.put(`${process.env.REACT_APP_API_URL}/agendaAula/${agenda._id}`, dados, this.getToken())
        
        const indexData = datas.findIndex(param => param.dataFormatada === moment(dados.ageaula_data_hora).format('DD/MM/YYYY'))

        const indexHorario = datas[indexData].horarios.findIndex(param => param.hora === moment(dados.ageaula_data_hora).format('HH:mm'))

        datas[indexData].horarios[indexHorario].aulaAluno = {
          ageaula_id: data.ageaula_id,
          ageaula_data_hora: dados.ageaula_data_hora,
          ageaula_duracao_aula: data.ageaula_duracao_aula,
          alunos: data.alunos.map(aluno => {
            return {
              AlunoAgenda: aluno.AlunoAgenda,
              aluno_id: aluno.aluno_id,
              aluno_matricula: aluno.aluno_matricula,
              fisica: {
                pesf_nome: aluno.fisica.pesf_nome
              }
            }
          }),
          aula: {
            aula_aluno_padrao: agenda.aula.aula_aluno_padrao,
            aula_duracao: agenda.aula.aula_duracao,
            aula_id: dados.ageaula_aula_id,
            aula_nome: agenda.aula.aula_nome,
            aula_tipo_horario: agenda.aula.aula_tipo_horario,
            limite_aula: {
              limauluni_limite: agenda.aula.limite_aula ? agenda.aula.limite_aula.limauluni_limite : agenda.aula.aula_aluno_padrao
            }
          },
          professor: {
            prof_id: data.ageaula_professor_id,
            funcionario: {
              fisica: {
                pesf_nome: data.professor.funcionario.fisica.pesf_nome
              }
            }
          },
          unidade:{
            unin_descricao: agenda.unin_descricao,
            unin_id: dados.ageaula_unin_id,
            unin_numero: agenda.unin_numero
          },
          alunosagenda:[{
            aluaula_aluno_id: aluno.aluno_id,
            usuario:{
              usu_id: usuario.usu_id,
              pessoa:{
                pes_id: pessoa_usuario.pessoa.pes_id,
                fisica: {
                  pesf_nome: pessoa_usuario.fisica.pesf_nome
                }
              }
            },
          }],
        }

        this.setState({
          disabledSalvar: false,
          modalConfirmacao: false,
          datas,
          liberaAula: this.state.confunin_perm_agenda_sem_cod ? true : ((this.state.verificaCodigo.qtdAulasFaltante + 1) > 0 ? true : false),
          verificaCodigo:{
            liberaAula: this.state.confunin_perm_agenda_sem_cod ? true : ((this.state.verificaCodigo.qtdAulasFaltante + 1) > 0 ? true : false),
            qtdAulasFaltante: this.state.verificaCodigo.qtdAulasFaltante - 1,
            possuiCodigo: this.state.confunin_perm_agenda_sem_cod ? true : this.state.verificaCodigo.possuiCodigo
          }
        })

      } catch ({response}) {
        // console.log(error)
        this.setState({ 
          disabledSalvar: false
        })
        this.setState({
          alerta: {
            open: true,
            autoHideDuration: 5000,
            severity: 'error',
            message: response.data.message
          }
        })
      }
    }

  }

  openFinPendente() {
    this.setState({
      openFinPendente: !this.state.openFinPendente
    })
  }

  handleCloseModalAulasAbertas(){
    this.setState({
      openModalAulasAbertas: false
    })
  }

  voltarTelaAgenda(){
    this.handleCloseModal()
    this.handleCloseModalAulasAbertas()
  }

  continuarAgendamento(){
    this.handleCloseModalAulasAbertas()
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  async avancarData(data_inicial, data_final){
    let {filtro} = this.state

    filtro.data_inicial = moment(data_inicial).add(7, 'days').format('YYYY-MM-DD')
    filtro.data_final = moment(data_final).add(7, 'days').format('YYYY-MM-DD')

    this.setState({
      filtro
    })

    await this.updateFieldAula({}, this.state.aula, false)
  }

  async voltarData(data_inicial, data_final){
    let {filtro} = this.state

    filtro.data_inicial = moment(data_inicial).subtract(7, 'days').format('YYYY-MM-DD')
    filtro.data_final = moment(data_final).subtract(7, 'days').format('YYYY-MM-DD')

    this.setState({
      filtro
    })

    await this.updateFieldAula({}, this.state.aula, false)
  }

  async voltarInicio(){
    await this.updateFieldAula({}, this.state.aula, true)
  }

  render() {
    const { agenda, datas, aula, ausencias, aluno, ultimasAulas, parcelasVencidas, openFinPendente, verificaCodigo, filtro } = this.state
    const feriados = this.state.feriados.list
    let pulaCelula = false

    return (
      <React.Fragment>
        {this.state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Agenda do Aluno...</p>
            </div>
          </React.Fragment>
        }
        {!this.state.loading &&
          <div>
            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={12}>
                <h5 className="titulo">Cadastro de Agenda</h5>
              </Grid>
            </Grid>
            <FormAgendaAula
              dados={agenda}
              updateField={e => this.updateField(e)}
              updateFieldUnidade={(event, value) => this.updateFieldUnidade(event, value)}
              updateFieldAula={(event, value) => this.updateFieldAula(event, value, true)}
              updateFieldCurso={(event, value) => this.updateFieldCurso(event, value)}
              updateFieldAluno={(event, value) => this.updateFieldAluno(event, value)}
              professores={this.state.professoresFiltrado.list}
              professor={this.state.professor}
              unidades={this.state.unidades.list}
              unidade={this.state.unidade}
              aulas={this.state.aulasFiltrada.list}
              aula={this.state.aula}
              alunos={this.state.alunosFiltrado.list}
              aluno={aluno}
              cursos={this.state.cursos.list}
              curso={this.state.curso}
              disabledUnidade={true}
            />
            <br />
            <hr />
            <br />
            <Grid container spacing={1} direction="row">
              <Grid style={{ borderRight: '1px solid #000' }} item md={6} xs={12} sm={6}>
                {this.state.loadingUltimaAula &&
                  <React.Fragment>
                    <div className="progressCircularRelative">
                      <CircularProgress />
                    </div>
                    <div className="progressTextRelative">
                      <p>Carregando Ultima Aula...</p>
                    </div>
                  </React.Fragment>
                }
                {!this.state.loadingUltimaAula &&
                  <React.Fragment>
                    <Grid container spacing={1} direction="row">
                      <Grid item md={12} xs={12} sm={12}>
                        {ultimasAulas.ultimaAulaUnidade &&
                          <p style={{ margin: '0px', fontSize: '16px' }}>
                            <b>Ultima Aula Unidade:</b> {moment(ultimasAulas.ultimaAulaUnidade.agenda.ageaula_data_hora).format('DD/MM/YYYY HH:mm')} - {ultimasAulas.ultimaAulaUnidade.agenda.aula.aula_nome} - {ultimasAulas.ultimaAulaUnidade.agenda.professor.funcionario.fisica.pesf_nome.split(' ')[0]} - |{ultimasAulas.ultimaAulaUnidade.historicos.map(historico => {
                              return (
                                <span> {historico.hisaluno_nota_aval} |</span>
                              )
                            })}
                          </p>
                        }
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} direction="row">
                      <Grid item md={12} xs={12} sm={12}>
                        {ultimasAulas.ultimaAulaComplementar &&
                          <p style={{ margin: '0px', fontSize: '16px' }}>
                            <b>Ultima Aula Complementar:</b> {moment(ultimasAulas.ultimaAulaComplementar.agenda.ageaula_data_hora).format('DD/MM/YYYY HH:mm')} - {ultimasAulas.ultimaAulaComplementar.agenda.aula.aula_nome} - {ultimasAulas.ultimaAulaComplementar.agenda.professor.funcionario.fisica.pesf_nome.split(' ')[0]} - |{ultimasAulas.ultimaAulaComplementar.historicos.map(historico => {
                              return (
                                <span> {historico.hisaluno_nota_aval} |</span>
                              )
                            })}
                          </p>
                        }
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} direction="row">
                      <Grid item md={12} xs={12} sm={12}>
                        {ultimasAulas.ultimaAulaReview &&
                          <p style={{ margin: '0px', fontSize: '16px' }}>
                            <b>Ultima Aula Review: </b>
                            {moment(ultimasAulas.ultimaAulaReview.agenda.ageaula_data_hora).format('DD/MM/YYYY HH:mm')} -
                            {ultimasAulas.ultimaAulaReview.agenda.aula.aula_nome} -
                            {ultimasAulas.ultimaAulaReview.agenda.professor.funcionario.fisica.pesf_nome.split(' ')[0]} - |
                            {ultimasAulas.ultimaAulaReview.historicos.map(historico => {
                              return (
                                <span> {historico.hisaluno_nota_aval} |</span>
                              )
                            })}
                          </p>
                        }
                      </Grid>
                    </Grid>
                  </React.Fragment>
                }
              </Grid>
              <Grid item md={6} xs={12} sm={6}>
                {this.state.loadingFinanceiroVencido &&
                  <React.Fragment>
                    <div className="progressCircularRelative">
                      <CircularProgress />
                    </div>
                    <div className="progressTextRelative">
                      <p>Carregando Financeiro...</p>
                    </div>
                  </React.Fragment>
                }
                {!this.state.loadingFinanceiroVencido && 
                  <div className={!openFinPendente ? 'cardFinPendenteClosed' : 'cardFinPendenteOpen'}>
                    <Grid container spacing={1} direction="row">
                      <Grid item md={11} xs={10} sm={11}>
                        <p style={{ margin: '0px', marginBottom: '5px', fontSize: '16px' }}><b>Financeiros Pendentes:</b></p>
                      </Grid>
                      <Grid item md={1} xs={2} sm={1}>
                        <IconButton style={{ height: '20px' }} onClick={e => this.openFinPendente()} color="primary" aria-label="editar">
                          {!openFinPendente &&
                            <KeyboardArrowDownIcon />
                          }
                          {openFinPendente &&
                            <KeyboardArrowUpIcon />
                          }
                        </IconButton>
                      </Grid>
                    </Grid>
                    {parcelasVencidas.length === 0 &&
                      <Grid container spacing={1} direction="row">
                        <Grid item md={12} xs={12} sm={12}>
                          <p style={{ margin: '0px', fontSize: '16px' }}>Nenhuma parcela pendente</p>
                        </Grid>
                      </Grid>
                    }
                    {parcelasVencidas.map(parcela => {
                      return (
                        <Grid container spacing={1} direction="row">
                          <Grid item md={12} xs={12} sm={12}>
                            <p style={{ margin: '0px', fontSize: '16px' }}>{parcela['conta.contpr_descricao']} {parcela['parcont_num']}/{parcela['conta.contpr_numparcela']} - Data: {moment(parcela['parcont_datavencimento']).format('DD/MM/YYYY')}</p>
                          </Grid>
                        </Grid>
                      )
                    })}
                  </div>
                }
              </Grid>
            </Grid>
            <br />
            <hr />
            <br />
            <Grid container spacing={1} direction="row">
              <Grid item md={1} xs={12} sm={3}>
                <span>Legenda:</span>
              </Grid>
              <Grid item md={2} xs={6} sm={5}>
                <div className="legHoraDisp"></div>
                <span>Horario Disponivel</span>
              </Grid>
              <Grid item md={2} xs={6} sm={4}>
                <div className="legAulaAberta"></div>
                <span>Aula Aberta</span>
              </Grid>
              <Grid item md={2} xs={6} sm={4}>
                <div className="legAulaMarcada"></div>
                <span>Aula Marcada</span>
              </Grid>
              <Grid item md={2} xs={6} sm={4}>
                <div className="legAulaLotada"></div>
                <span>Aula Lotada</span>
              </Grid>
            </Grid>
            <Grid container spacing={1} direction="row">
              <Grid item md={2} xs={6} sm={4}>
                <span>A.V - Antes da Vigência</span>
              </Grid>
              <Grid item md={2} xs={6} sm={4}>
                <span>P.V - Pós da Vigência</span>
              </Grid>
            </Grid>
            <hr />
            <br />
            <Grid container spacing={1} direction="row">
              {!verificaCodigo.possuiCodigo && 
                <React.Fragment>
                  {verificaCodigo.qtdAulasFaltante > 0 &&
                    <Grid style={{textAlign: 'center', backgroundColor: verificaCodigo.qtdAulasFaltante > 0 ? '#90EE90' : 'yellow'}} item md={12} xs={12} sm={12}>
                      {verificaCodigo.liberaAula &&
                        <React.Fragment>
                            <span>Esse aluno ainda possui {verificaCodigo.qtdAulasFaltante} {verificaCodigo.qtdAulasFaltante === 1 ? 'Aula' : 'Aulas'} disponíveis sem MD.</span>
                        </React.Fragment>
                      }
                      {!verificaCodigo.liberaAula && 
                        <span>Esse aluno já marcou 2 aulas sem MD, e não pode mais marcar aulas.</span>
                      }
                    </Grid>
                  }
                </React.Fragment>
              }
              {!verificaCodigo.liberaAula &&
                <React.Fragment>
                  {!verificaCodigo.possuiCodigo &&
                    <Grid style={{textAlign: 'center', backgroundColor: 'yellow'}} item md={12} xs={12} sm={12}>
                      <span>Esse aluno já marcou 2 aulas sem MD, e não pode mais marcar aulas.</span>
                    </Grid>
                  }
                </React.Fragment>
              }
            </Grid>
            <br />
            <hr />
            <Grid container spacing={1} direction="row" style={{marginTop: 5}}>
              <Grid item md={4} xs={12} sm={4}></Grid>
              <Grid item md={4} xs={12} sm={4}>
                <Grid container spacing={1} direction="row">
                  <Grid style={{textAlign: 'center'}} item md={1} xs={12} sm={2}>
                    {filtro.data_inicial !== moment().format('YYYY-MM-DD') &&
                      <Tooltip title='Voltar para o inicio' aria-label="add" arrow>
                        <FirstPageIcon style={{fontSize: 40, cursor: 'pointer'}} onClick={() => this.voltarInicio()}/>
                      </Tooltip>
                    }
                  </Grid>
                  <Grid style={{textAlign: 'center'}} item md={1} xs={12} sm={2}>
                    {filtro.data_inicial !== moment().format('YYYY-MM-DD') &&
                      <Tooltip title='Voltar' aria-label="add" arrow>
                        <NavigateBeforeIcon style={{fontSize: 40, cursor: 'pointer'}} onClick={() => this.voltarData(filtro.data_inicial, filtro.data_final)}/>
                      </Tooltip>
                    }
                  </Grid>
                  <Grid style={{textAlign: 'center'}} item md={9} xs={12} sm={8}>
                    <span style={{fontSize: 24}}>{moment(filtro.data_inicial).format('DD/MM')} - {moment(filtro.data_final).format('DD/MM')}</span>
                  </Grid>
                  <Grid style={{textAlign: 'center'}} item md={1} xs={12} sm={2}>
                    <Tooltip title='Avançar' aria-label="add" arrow>
                      <NavigateNextIcon style={{fontSize: 40, cursor: 'pointer'}} onClick={() => this.avancarData(filtro.data_inicial, filtro.data_final)}/>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4} xs={12} sm={4}></Grid>
            </Grid>
            {this.state.loadingAgenda &&
              <React.Fragment>
                <br />
                <div className="progressCircularRelative">
                  <CircularProgress />
                </div>
                <div className="progressTextRelative">
                  <p>Carregando Agenda...</p>
                </div>
              </React.Fragment>
            }
            {!this.state.loadingAgenda &&
              <React.Fragment>
                <table style={{marginTop: 10}} className="lista sempadding tabelaAgenda">
                  <thead>
                    <tr className="titulos acompanha agenda">
                      <th>Data</th>
                      <th>8h</th>
                      <th>9h</th>
                      <th>10h</th>
                      <th>11h</th>
                      <th>12h</th>
                      <th>13h</th>
                      <th>14h</th>
                      <th>15h</th>
                      <th>16h</th>
                      <th>17h</th>
                      <th>18h</th>
                      <th>19h</th>
                      <th>20h</th>
                      <th>21h</th>
                    </tr>
                  </thead>
                  <tbody>
                    {datas.map(data => {

                      const index = feriados.findIndex(feriado => feriado.fer_data === data.dataFormatada)

                      if (index === -1) {
                        if (data.diaSemana === 'SÁBADO') {
                          return (
                            <tr className="borderBottom" key={data.dia}>
                              <td className="w-60">
                                <p><span>{data.dia}</span>/{data.mes}</p>
                                <p>{data.diaSemana}</p>
                              </td>
                              {data.horarios.map(horario => {
                                if (!pulaCelula) {

                                  if (new Date(data.dataSemFormato).getTime() > new Date(aluno.contratos_ativos.sort((a, b) => (a.contm_data_fim_vigencia > b.contm_data_fim_vigencia) ? -1 : ((b.contm_data_fim_vigencia > a.contm_data_fim_vigencia) ? 1 : 0))[0].contm_data_fim_vigencia).getTime()) {
                                    pulaCelula = false
                                    return (
                                      <td key={`${data.dia}-${horario.hora}`}>
                                        <div>
                                          <p>C.E</p>
                                        </div>
                                      </td>
                                    )
                                  }

                                  if (new Date(data.dataSemFormato).getTime() < new Date(aluno.contrato.contm_data_inicio_vigencia).getTime()) {
                                    pulaCelula = false
                                    return (
                                      <td key={`${data.dia}-${horario.hora}`}>
                                        <div>
                                          <p>A.V</p>
                                        </div>
                                      </td>
                                    )
                                  }

                                  const indexAusencia = ausencias.list.findIndex(param => new Date(param.austemp_data_inicial).getTime() <= new Date(data.dataSemFormato).getTime() && new Date(param.austemp_data_final).getTime() >= new Date(data.dataSemFormato).getTime())

                                  if (indexAusencia !== -1) {
                                    pulaCelula = false
                                    return (
                                      <td key={`${data.dia}-${horario.hora}`}>
                                        <div>
                                          <p>A.T</p>
                                        </div>
                                      </td>
                                    )
                                  }

                                  if (horario.aulaAluno) {
                                    if (horario.aulaAluno.ageaula_duracao_aula === 1) {
                                      pulaCelula = false
                                    } else {
                                      pulaCelula = true
                                    }
                                    return (
                                      <td colSpan={horario.aulaAluno.ageaula_duracao_aula} key={`${data.dia}-${horario.hora}`}>
                                        <LightTooltip
                                          title={this.renderTooltip(horario.aulaAluno)}
                                        >
                                          <div className="aula_marcada" onClick={() => this.openCancelarAula(horario.aulaAluno)}>
                                            <p>{horario.aulaAluno.aula.aula_nome}</p>
                                            {!horario.aulaAluno.aula.limite_aula &&
                                              <p>{horario.aulaAluno.alunos.length} / {horario.aulaAluno.aula.aula_aluno_padrao}</p>
                                            }
                                            {horario.aulaAluno.aula.limite_aula &&
                                              <p>{horario.aulaAluno.alunos.length} / {horario.aulaAluno.aula.limite_aula.limauluni_limite}</p>
                                            }
                                          </div>
                                        </LightTooltip>
                                      </td>
                                    )
                                  }

                                  if (new Date(`${data.ano}-${data.mes}-${data.dia} ${horario.hora}:00`).getTime() < new Date().getTime()) {
                                    pulaCelula = false
                                    return (
                                      <td key={`${data.dia}-${horario.hora}`}>
                                        <div>
                                          <p>-</p>
                                        </div>
                                      </td>
                                    )
                                  }

                                  if(verificaCodigo.liberaAula || verificaCodigo.possuiCodigo){
                                    if (horario.aulaAberta) {
                                      
                                      if (aula.aula_tipo_horario === 'FIXO') {
                                        let limiteAlunos = 0

                                        if (!horario.aulaAberta.aula.limite_aula) {
                                          limiteAlunos = horario.aulaAberta.aula.aula_aluno_padrao
                                        }
                                        if (horario.aulaAberta.aula.limite_aula) {
                                          limiteAlunos = horario.aulaAberta.aula.limite_aula.limauluni_limite
                                        }

                                        if (horario.aulaAberta.alunos.length >= limiteAlunos) {
                                          if (horario.aulaAberta.ageaula_duracao_aula === 1) {
                                            pulaCelula = false
                                          } else {
                                            pulaCelula = true
                                          }
                                          return (
                                            <td colSpan={horario.aulaAberta.ageaula_duracao_aula} key={`${data.dia}-${horario.hora}`}>
                                              <LightTooltip
                                                title={this.renderTooltip(horario.aulaAberta)}
                                              >
                                                <div className="aula_lotada">
                                                  <p> {horario.aulaAberta.aula.aula_nome}</p>
                                                  {!horario.aulaAberta.aula.limite_aula &&
                                                    <p>{horario.aulaAberta.alunos.length} / {horario.aulaAberta.aula.aula_aluno_padrao}</p>
                                                  }
                                                  {horario.aulaAberta.aula.limite_aula &&
                                                    <p>{horario.aulaAberta.alunos.length} / {horario.aulaAberta.aula.limite_aula.limauluni_limite}</p>
                                                  }
                                                </div>
                                              </LightTooltip>
                                            </td>
                                          )
                                        }
                                      }
                                    }

                                    if (horario.aulaAberta) {
                                      
                                      let limiteAlunos = 0

                                      if (!horario.aulaAberta.aula.limite_aula) {
                                        limiteAlunos = horario.aulaAberta.aula.aula_aluno_padrao
                                      }
                                      if (horario.aulaAberta.aula.limite_aula) {
                                        limiteAlunos = horario.aulaAberta.aula.limite_aula.limauluni_limite
                                      }
                                      if (horario.aulaAberta.alunos.length < limiteAlunos) {
                                        if (horario.aulaAberta.ageaula_duracao_aula === 1) {
                                          pulaCelula = false
                                        } else {
                                          pulaCelula = true
                                        }
                                        return (
                                          <td colSpan={horario.aulaAberta.ageaula_duracao_aula} key={`${data.dia}-${horario.hora}`}>
                                            <LightTooltip
                                              title={this.renderTooltip(horario.aulaAberta)}
                                            >
                                              <div className="aula_aberta" onClick={() => this.openDiaAula(horario.hora, data, true, horario.aulaAberta, horario.professoresDisp)}>
                                                <p> {horario.aulaAberta.aula.aula_nome}</p>
                                                {!horario.aulaAberta.aula.limite_aula &&
                                                  <p>{horario.aulaAberta.alunos.length} / {horario.aulaAberta.aula.aula_aluno_padrao}</p>
                                                }
                                                {horario.aulaAberta.aula.limite_aula &&
                                                  <p>{horario.aulaAberta.alunos.length} / {horario.aulaAberta.aula.limite_aula.limauluni_limite}</p>
                                                }
                                              </div>
                                            </LightTooltip>
                                          </td>
                                        )
                                      }


                                    }

                                    if (aula.aula_tipo_horario !== 'FIXO') {
                                      if (horario.professoresDisp.length > 0) {
                                        if (aula.aula_duracao === 1) {
                                          pulaCelula = false
                                        } else {
                                          pulaCelula = true
                                        }
                                        return (
                                          <td colSpan={aula.aula_duracao} key={`${data.dia}-${horario.hora}`}>
                                            <div className="aula_disp" onClick={() => this.openDiaAula(horario.hora, data, false, {}, horario.professoresDisp)}>
                                              <p>
                                                {horario.hora.replace(":00", "h")}
                                              </p>
                                            </div>
                                          </td>

                                        )
                                      }
                                    }
                                  }

                                  pulaCelula = false
                                  return (
                                    <td key={`${data.dia}-${horario.hora}`}>
                                      <div>
                                        <p>-</p>
                                      </div>
                                    </td>
                                  )
                                } else {
                                  pulaCelula = false
                                  return false
                                }
                              })}
                            </tr>
                          )
                        } else {
                          return (
                            <tr key={data.dia} >
                              <td className="w-60">
                                <p><span>{data.dia}</span>/{data.mes}</p>
                                <p>{data.diaSemana}</p>
                              </td>
                              {
                                data.horarios.map(horario => {
                                  if (!pulaCelula) {

                                    if (new Date(data.dataSemFormato).getTime() > new Date(aluno.contratos_ativos.sort((a, b) => (a.contm_data_fim_vigencia > b.contm_data_fim_vigencia) ? -1 : ((b.contm_data_fim_vigencia > a.contm_data_fim_vigencia) ? 1 : 0))[0].contm_data_fim_vigencia).getTime()) {
                                      pulaCelula = false
                                      return (
                                        <td key={`${data.dia}-${horario.hora}`}>
                                          <div>
                                            <p>C.E</p>
                                          </div>
                                        </td>
                                      )
                                    }

                                    if (new Date(data.dataSemFormato).getTime() < new Date(aluno.contrato.contm_data_inicio_vigencia).getTime()) {
                                      pulaCelula = false
                                      return (
                                        <td key={`${data.dia}-${horario.hora}`}>
                                          <div>
                                            <p>A.V</p>
                                          </div>
                                        </td>
                                      )
                                    }

                                    const indexAusencia = ausencias.list.findIndex(param => new Date(param.austemp_data_inicial).getTime() <= new Date(data.dataSemFormato).getTime() && new Date(param.austemp_data_final).getTime() >= new Date(data.dataSemFormato).getTime())

                                    if (indexAusencia !== -1) {
                                      pulaCelula = false
                                      return (
                                        <td key={`${data.dia}-${horario.hora}`}>
                                          <div>
                                            <p>A.T</p>
                                          </div>
                                        </td>
                                      )
                                    }

                                    if (horario.aulaAluno) {
                                      if (horario.aulaAluno.ageaula_duracao_aula === 1) {
                                        pulaCelula = false
                                      } else {
                                        pulaCelula = true
                                      }
                                      return (
                                        <td colSpan={horario.aulaAluno.ageaula_duracao_aula} key={`${data.dia}-${horario.hora}`}>
                                          <LightTooltip
                                            title={this.renderTooltip(horario.aulaAluno)}
                                          >
                                            <div className="aula_marcada" onClick={() => this.openCancelarAula(horario.aulaAluno)}>
                                              <p>{horario.aulaAluno.aula.aula_nome}</p>
                                              {!horario.aulaAluno.aula.limite_aula &&
                                                <p>{horario.aulaAluno.alunos.length} / {horario.aulaAluno.aula.aula_aluno_padrao}</p>
                                              }
                                              {horario.aulaAluno.aula.limite_aula &&
                                                <p>{horario.aulaAluno.alunos.length} / {horario.aulaAluno.aula.limite_aula.limauluni_limite}</p>
                                              }
                                            </div>
                                          </LightTooltip>
                                        </td>
                                      )
                                    }

                                    if (new Date(`${data.ano}-${data.mes}-${data.dia} ${horario.hora}:00`).getTime() < new Date().getTime()) {
                                      pulaCelula = false
                                      return (
                                        <td key={`${data.dia}-${horario.hora}`}>
                                          <div>
                                            <p>-</p>
                                          </div>
                                        </td>
                                      )
                                    }

                                    if(verificaCodigo.liberaAula || verificaCodigo.possuiCodigo){
                                      if (horario.aulaAberta) {
                                        if (aula.aula_tipo_horario === 'FIXO') {
                                          
                                          let limiteAlunos = 0

                                          if (!horario.aulaAberta.aula.limite_aula) {
                                            limiteAlunos = horario.aulaAberta.aula.aula_aluno_padrao
                                          }
                                          if (horario.aulaAberta.aula.limite_aula) {
                                            limiteAlunos = horario.aulaAberta.aula.limite_aula.limauluni_limite
                                          }

                                          if (horario.aulaAberta.alunos.length >= limiteAlunos) {
                                            if (horario.aulaAberta.ageaula_duracao_aula === 1) {
                                              pulaCelula = false
                                            } else {
                                              pulaCelula = true
                                            }
                                            return (
                                              <td colSpan={horario.aulaAberta.ageaula_duracao_aula} key={`${data.dia}-${horario.hora}`}>
                                                <LightTooltip
                                                  title={this.renderTooltip(horario.aulaAberta)}
                                                >
                                                  <div className="aula_lotada">
                                                    <p> {horario.aulaAberta.aula.aula_nome}</p>
                                                    {!horario.aulaAberta.aula.limite_aula &&
                                                      <p>{horario.aulaAberta.alunos.length} / {horario.aulaAberta.aula.aula_aluno_padrao}</p>
                                                    }
                                                    {horario.aulaAberta.aula.limite_aula &&
                                                      <p>{horario.aulaAberta.alunos.length} / {horario.aulaAberta.aula.limite_aula.limauluni_limite}</p>
                                                    }
                                                  </div>
                                                </LightTooltip>
                                              </td>
                                            )
                                          }
                                        }
                                      }

                                      if (horario.aulaAberta) {

                                        let limiteAlunos = 0

                                        if (!horario.aulaAberta.aula.limite_aula) {
                                          limiteAlunos = horario.aulaAberta.aula.aula_aluno_padrao
                                        }
                                        if (horario.aulaAberta.aula.limite_aula) {
                                          limiteAlunos = horario.aulaAberta.aula.limite_aula.limauluni_limite
                                        }

                                        if(horario.aulaAberta.alunos.length >= limiteAlunos){
                                          if (horario.aulaAberta.ageaula_duracao_aula === 1) {
                                            pulaCelula = false
                                          } else {
                                            pulaCelula = true
                                          }
                                          return (
                                            <td colSpan={horario.aulaAberta.ageaula_duracao_aula} key={`${data.dia}-${horario.hora}`}>
                                              <LightTooltip
                                                title={this.renderTooltip(horario.aulaAberta)}
                                              >
                                                <div className="aula_lotada">
                                                  <p> {horario.aulaAberta.aula.aula_nome}</p>
                                                  {!horario.aulaAberta.aula.limite_aula &&
                                                    <p>{horario.aulaAberta.alunos.length} / {horario.aulaAberta.aula.aula_aluno_padrao}</p>
                                                  }
                                                  {horario.aulaAberta.aula.limite_aula &&
                                                    <p>{horario.aulaAberta.alunos.length} / {horario.aulaAberta.aula.limite_aula.limauluni_limite}</p>
                                                  }
                                                </div>
                                              </LightTooltip>
                                            </td>
                                          )
                                        }

                                        if (horario.aulaAberta.alunos.length < limiteAlunos) {
                                          if (horario.aulaAberta.ageaula_duracao_aula === 1) {
                                            pulaCelula = false
                                          } else {
                                            pulaCelula = true
                                          }
                                          return (
                                            <td colSpan={horario.aulaAberta.ageaula_duracao_aula} key={`${data.dia}-${horario.hora}`}>
                                              <LightTooltip
                                                title={this.renderTooltip(horario.aulaAberta)}
                                              >
                                                <div className="aula_aberta" onClick={() => this.openDiaAula(horario.hora, data, true, horario.aulaAberta, horario.professoresDisp)}>
                                                  <p> {horario.aulaAberta.aula.aula_nome}</p>
                                                  {!horario.aulaAberta.aula.limite_aula &&
                                                    <p>{horario.aulaAberta.alunos.length} / {horario.aulaAberta.aula.aula_aluno_padrao}</p>
                                                  }
                                                  {horario.aulaAberta.aula.limite_aula &&
                                                    <p>{horario.aulaAberta.alunos.length} / {horario.aulaAberta.aula.limite_aula.limauluni_limite}</p>
                                                  }
                                                </div>
                                              </LightTooltip>
                                            </td>
                                          )
                                        }


                                      }

                                      if (aula.aula_tipo_horario !== 'FIXO') {
                                        if (horario.professoresDisp.length > 0) {
                                          if (aula.aula_duracao === 1) {
                                            pulaCelula = false
                                          } else {
                                            pulaCelula = true
                                          }
                                          return (
                                            <td colSpan={aula.aula_duracao} key={`${data.dia}-${horario.hora}`}>
                                              <div className="aula_disp" onClick={() => this.openDiaAula(horario.hora, data, false, {}, horario.professoresDisp)}>
                                                <p>
                                                  {horario.hora.replace(":00", "h")}
                                                </p>
                                              </div>
                                            </td>

                                          )
                                        }
                                      }
                                    }

                                    pulaCelula = false
                                    return (
                                      <td key={`${data.dia}-${horario.hora}`}>
                                        <div>
                                          <p>-</p>
                                        </div>
                                      </td>
                                    )
                                  } else {
                                    pulaCelula = false
                                    return false
                                  }
                                })
                              }
                            </tr>
                          )
                        }

                      } else {
                        return (
                          <tr className="borderBottom feriado" key={data.dia}>
                            <td className="w-60">
                              <p><span>{data.dia}</span>/{data.mes}</p>
                              <p>{data.diaSemana}</p>
                            </td>
                            <td colspan='14'>
                              <p>{feriados[index].fer_descricao}</p>
                            </td>
                          </tr>
                        )
                      }
                    })}
                  </tbody>
                </table>
              </React.Fragment>
            }
          </div>
        }
        <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        
        <ModalRemoveAlunoAgenda
          open={this.state.modalCancelamento}
          handleClose={e => this.handleCloseModalCancelamento(e)}
          dados={this.state.dadosModalConfirmacao}
          confirmar={e => this.confirmarCancelamento(e)}
          voltar={e => this.voltarCancelamento(e)}
          disabledSalvar={this.state.disabledSalvar} 
        />

        <ModalConfirmacaoAgenda
          open={this.state.modalConfirmacao}
          professor={this.state.professor}
          professores={this.state.dadosModalConfirmacao.professores}
          dados={this.state.dadosModalConfirmacao}
          updateFieldFuncionario={(event, value) => this.updateFieldFuncionario(event, value)}
          handleClose={e => this.handleCloseModal(e)}
          salvar={e => this.salvar(e)}
          voltar={e => this.voltar(e)}
          disabledSalvar={this.state.disabledSalvar} 
        />

        <ModalAulasAbertas 
          open={this.state.openModalAulasAbertas}
          handleClose={e => this.handleCloseModalAulasAbertas(e)}
          aulas_abertas={this.state.aulas_abertas.list}
          voltar={e => this.voltarTelaAgenda()}
          confirmar={e => this.continuarAgendamento()}
        />

        <Alert 
          open={this.state.alerta.open}
          handleClose={e => this.handleCloseAlerta()} 
          severity={this.state.alerta.severity}
          message={this.state.alerta.message} 
        />
      </React.Fragment >
    )
  }
}