import React, { Component } from 'react'
import './Relatorios.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'
import Table from '../../components/Table'
import { Grid, Button, TextField } from '@material-ui/core'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalErro from './../../components/modals/Erro'
import ModalFlyHighUnidade from './../../components/modals/FlyHighUnidade'
import Chart from "react-google-charts";

import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import moment from 'moment'

const initalState = {
  filtro: {
    relatorio: "",
    data_inicial: '',
    data_final: '',
    unin_id: "",
		func_id: "",
		fun_id: ""
  },
  cabecalhoTabela: [],
  acoesTabela: [],
  relatorios: {
    list: []
  },
  relatorios_options: {
    list: [{
      perm:{
        perm_visualizar: false
      }
    }]
  },
  loading: false,
  loadingDownload: false,
  urlView: '',
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
	funcionarios: {
		list: []
	},
	funcoes:{
		list: []
	},
  subCabecalhoTabela: [],
  subAcoesTabela: [],
  subRelatorios: {
    list: []
  },
  subUrlView: '',
  modalFlyHighUnidade: false,
  filtroFuncionario: {
    fun_id: "",
  },
  filtroFuncao: {
    func_id: ""
  },
	dataChart: [],
	unidadeSelecionada: {
		unin_id: '',
		unin_numero: '',
		unin_descricao: '',
		pontos_matricula : 0,
		pontos_rematricula : 0,
		pontos_prorrogacao : 0,
		md : 0,
		total: 0
	},
	subDataChartGeral: [],
	subDataChartItem: [],
  minhaUnidade: {
    unin_id: '',
    unin_tipo: ''
  },
  qtdRowColaborador: 0,
  porCargo: [],
  qtdRowCargo: 0,
  tab: 0,
  mostrarRelatorio: false,
  listaPontuacao: [],
  cabecalhoTabelaPontosCurso: [
    { id: 'item', numeric: false, disablePadding: true, label: 'Item' },
    { id: 'ponto_matricula', numeric: true, disablePadding: true, label: 'Matricula' },
    { id: 'ponto_rematricula', numeric: true, disablePadding: false, label: 'Rematricula' },
    { id: 'pronto_prorrogacao', numeric: true, disablePadding: false, label: 'Prorrogação' }
  ],
  acoesTabelaPontosCurso: [],
  cabecalhoTabelaPontosProduto: [
    { id: 'item', numeric: false, disablePadding: true, label: 'Item' },
    { id: 'ponto_material', numeric: true, disablePadding: true, label: 'Matricula' }
  ],
  acoesTabelaPontosProduto: [],
  listaPontuacaoProduto: []
}

const relatoriosAux = {
  list: []
}


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default class FlyHigh extends Component {
  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    const perfil = JSON.parse(localStorage.getItem('perfil'))
    
    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'rel-ranking')

    const pessoa = JSON.parse(localStorage.getItem('pessoa'))

    const dados = JSON.parse(localStorage.getItem('grafico_unidade'))

    if(dados){
      this.setState({
        filtro: dados.filtro,
        tab: dados.tab
      })

      console.log(dados.porColaborador)

      this.setState({
        dataChart: dados.dataChart,
        porColaborador: dados.porColaborador,
        qtdRowColaborador: dados.porColaborador.length - 1,
        porCargo: dados.porCargo,
        qtdRowCargo: dados.porCargo.length - 1,
        mostrarRelatorio: true,
        listaPontuacao: dados.listaPontuacao
      })
     
    }

    this.setState({
      relatorios_options: {
        list: permissoes.map( value => {
          return {
            tela_descricao: value.tela.tela_descricao,
            tela_slug: value.tela.tela_slug,
            perm: {
              perm_visualizar: value.perm_visualizar
            }
          }
        })
      },
      minhaUnidade: pessoa.fisica.funcionario.contrato.unidadetrabalho
    })
  }

  async updateFieldFiltro(event) {
    const filtro = this.state.filtro

    filtro[event.target.name] = event.target.value

    this.setState({ filtro })
  }

  async filtrar() {
    const filtro = this.state.filtro

    this.setState({
      loading: true
    })

    if (filtro.data_inicial === '') {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: 'Por favor informar a data inicial'
      })
      this.setState({
        loading: false
      })
      return
    }

    if (filtro.data_final === '') {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: 'Por favor informar a data final'
      })
      this.setState({
        loading: false
      })
      return
    }

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.unin_id
        })
      }

      filtro.unidadesnegocio = dados.unidadesnegocio
			filtro.relatorio = 'fly-high'

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/relatorio`, filtro, this.getToken())

			let dataChart = [
				["Unidade", "Pontos", { role: "style" }, { role: 'annotation' }]
			]

      let porCargo = []

      let porColaborador = [['Assessores', 'Matricula', 'Rematricula', 'Prorrogacao', 'MD']]

			data.list.por_unidades.forEach((unidade, key) => {
				var randomColor = Math.floor(Math.random()*16777215).toString(16);

				dataChart.push(
					[`${key + 1}º - ${unidade.unin_numero} - ${unidade.unin_descricao}`,unidade.total, `#${randomColor}`, unidade.total ]
				)
			});

      data.list.por_colaboradores.filter(param => param.pesf_id !== 14).forEach((assessor, key) => {
				porColaborador.push(
					[`${key + 1}º - ${assessor.colaborador} - ${assessor.total}`, assessor.pontos_matricula, assessor.pontos_rematricula, assessor.pontos_prorrogacao, assessor.md ]
				)
			});

      data.list.por_cargo.forEach((cargo, key) => {

        let colaboradores = [['Assessores', 'Matricula', 'Rematricula', 'Prorrogacao', 'MD']]
        
        cargo.colaboradores.filter(param => param.pesf_id !== 14).sort((a, b) => (a.total > b.total) ? -1 : ((b.total > a.total) ? 1 : 0)).forEach((assessor, key) => {
          colaboradores.push(
            [`${key + 1}º - ${assessor.colaborador} - ${assessor.total}`, assessor.pontos_matricula, assessor.pontos_rematricula, assessor.pontos_prorrogacao, assessor.md ]
          )
        });

        porCargo.push({
          func_descricao: cargo.func_descricao,
          func_nivel: cargo.func_nivel,
          func_id: cargo.func_id,
          colaboradores
        })
			});
      
      this.setState({
        cabecalhoTabela: data.cabecalhoTabela,
        acoesTabela: data.acoesTabela,
        relatorios: {
          list: data.list.por_unidades
        },
        relatoriosFiltrado: {
          list: data.list.por_unidades
        },
        urlView: data.urlView,
				dataChart,
        porColaborador,
        qtdRowColaborador: data.list.por_colaboradores.length,
        porCargo,
        qtdRowCargo: data.list.por_cargo.length,
        mostrarRelatorio: true,
        listaPontuacao: data.list.lista_pontuacoes
      })

      this.setState({
        loading: false
      })

      localStorage.removeItem('grafico_unidade')

    } catch (error) {
      this.setState({
        loading: false
      })
      console.log(error)
    }
  }

  updateFieldFiltroFuncionario(event){
    const relatorios = this.state.relatorios.list
    const filtroFuncionario = this.state.filtroFuncionario

    let list = []

    if (event.target.value !== '') {
      list = relatorios.filter(param => param.fun_id === parseInt(event.target.value))
    } else {
      list = relatorios
    }

    filtroFuncionario[event.target.name] = event.target.value

    this.setState({
      relatoriosFiltrado: {
        list
      },
      filtroFuncao: {
        func_id: ''
      },
      filtroFuncionario
    })
  }

  updateFieldFiltroFuncao(event){
    const relatorios = this.state.relatorios.list
    const filtroFuncao = this.state.filtroFuncao

    let list = []

    if (event.target.value !== '') {
      list = relatorios.filter(param => param.func_id === parseInt(event.target.value))
    } else {
      list = relatorios
    }

    filtroFuncao[event.target.name] = event.target.value

    this.setState({
      relatoriosFiltrado: {
        list
      },
      filtroFuncao,
      filtroFuncionario: {
        fun_id: ''
      }
    })
  }

  async imprimir(value) {

    const { tab, dataChart, filtro, porCargo, porColaborador, listaPontuacao } = this.state

    const dados = {
      dataChart,
      porCargo,
      porColaborador,
      filtro,
      listaPontuacao,
      tab
    }

    localStorage.setItem('grafico_unidade', JSON.stringify(dados));

    if(tab === 0){
      this.props.history.push("/fly_high/impressao/unidade");
      this.setState({ loading: false })
      return;
    }else if(tab === 1){
      this.props.history.push("/fly_high/impressao/colaborador");
      this.setState({ loading: false })
      return;
    }else if(tab === 2){
      this.props.history.push("/fly_high/impressao/cargo");
      this.setState({ loading: false })
      return;
    }

    // if(tab !== 3){
    //   window.print() 
    // }else{
    //   // window.print() 
    //   // this.setState({
    //   //   loadingDownload: true
    //   // })
  
    //   // try {
    //   //   const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))
  
    //   //   const dados = {
    //   //     unidadesnegocio: acesso_atual.map(acesso => {
    //   //       return acesso.unin_id
    //   //     })
    //   //   }
  
    //   //   let filtroImpressao = {}
  
    //   //   if(value === 'principal'){
    //   //     filtro.unidadesnegocio = dados.unidadesnegocio
    //   //     filtro.list = relatorios.list
  
    //   //     filtroImpressao = { ...filtro}
    //   //   }else{
    //   //     subFiltro.titulo = 'Alunos'
    //   //     subFiltro.unidadesnegocio = dados.unidadesnegocio
    //   //     subFiltro.list = subRelatorios.list
  
    //   //     filtroImpressao = {...subFiltro}
    //   //   }
  
    //   //   const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/relatorio/imprimir`, filtroImpressao, this.getToken())
  
    //   //   const dadosImprimir = {
    //   //     path: data
    //   //   }
  
    //   //   const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })
  
    //   //   const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });
  
    //   //   saveAs(pdfBlob, `Matricula-por-Cargo.pdf`);
  
    //   //   this.setState({
    //   //     loadingDownload: false
    //   //   })
  
    //   // } catch (error) {
    //   //   this.setState({
    //   //     loadingDownload: false
    //   //   })
    //   //   console.log(error)
    //   // }
    // }


  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  registrarRows(value){
    
    relatoriosAux.list = value
    
    // this.setState({
    //   relatoriosAux: {
    //     list: value
    //   }
    // })
  }

  handleCloseSubRelatorio(){
    this.setState({
      modalSubRelatorio: false
    })
  }

	async openModalUnidade(row){
		try {
			const filtro = this.state.filtro
			filtro.relatorio = 'fly-high-assessor'
			filtro.unin_id = row.unin_id

			const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/relatorio`, filtro, this.getToken())
			
			let subDataChartGeral = [['Assessores', 'Pontos', { role: "style" }, { role: 'annotation' }]]
			let subDataChartItem = [['Assessores', 'Matricula', 'Rematricula', 'Prorrogacao', 'MD']]

			data.list.forEach((assessor, key) => {
				var randomColor = Math.floor(Math.random()*16777215).toString(16);

				subDataChartGeral.push(
					[`${key + 1}º - ${assessor.pesf_nome}`, assessor.total, `#${randomColor}`, assessor.total ]
				)

				subDataChartItem.push(
					[`${key + 1}º - ${assessor.pesf_nome}`, assessor.pontos_matricula, assessor.pontos_rematricula, assessor.pontos_prorrogacao, assessor.md ]
				)
			});

			this.setState({
				modalFlyHighUnidade: true,
				unidadeSelecionada: row,
				subCabecalhoTabela: data.cabecalhoTabela,
				subAcoesTabela: data.acoesTabela,
				subRelatorios: {
					list: data.list
				},
				subUrlView: data.urlView,
				subDataChartGeral,
				subDataChartItem
			})
		} catch (error) {
			console.log(error)
		}

	}

	handleCloseFlyHighUnidade(){
		this.setState({
			modalFlyHighUnidade: false,
			unidadeSelecionada: {
				unin_id: '',
				unin_numero: '',
				unin_descricao: '',
				pontos_matricula : 0,
				pontos_rematricula : 0,
				pontos_prorrogacao : 0,
				md : 0,
				total: 0
			}
		})
	}

  handleChange = (event, newValue) => {
    this.setState({
      tab: newValue
    })
  };

  gerarDescricao(descricao){
    return (
      <div className="content-fly" dangerouslySetInnerHTML={{__html: descricao}}></div>
    );
  }

  render() {
    const { filtro, cabecalhoTabelaPontosCurso, acoesTabelaPontosCurso, cabecalhoTabelaPontosProduto, acoesTabelaPontosProduto, 
      loadingDownload, relatoriosFiltrado, qtdRowColaborador, tab, mostrarRelatorio, listaPontuacao } = this.state
		
		return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          <Grid container spacing={1} direction="row" className="borderBottom">
            <Grid item md={11} xs={10}>
              <h1 className="titulo">Fly High</h1>
            </Grid>
            {loadingDownload &&
              <Grid item md={1} xs={2}>
                <CircularProgress />
              </Grid>
            }
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_20">
						<Grid item md={3} xs={12} sm={5}>
              <TextField className="input" type="date" label="Data Incial" variant="outlined" size="small" name="data_inicial" value={filtro.data_inicial} onChange={(e) => this.updateFieldFiltro(e)} InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item md={3} xs={12} sm={5}>
              <TextField className="input" type="date" label="Data Final" variant="outlined" size="small" name="data_final" value={filtro.data_final} onChange={(e) => this.updateFieldFiltro(e)} InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item md={2} xs={12} sm={2}>
              <Button fullWidth variant="contained" color="primary" onClick={() => this.filtrar()} disabled={filtro.data_inicial === '' ? true : (filtro.data_final === '' ? true : false)}>
                Filtrar
              </Button>
            </Grid>
            {this.state.dataChart.length > 1 &&
              <Grid item md={2} xs={12} sm={3}>
                {tab !== 3 &&
                  <Button fullWidth variant="contained" color="secondary" onClick={() => this.imprimir()} disabled={loadingDownload}>
                    Imprimir
                  </Button>
                }
              </Grid>
            }
          </Grid>
          <br />
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Relatório...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <React.Fragment>
              {mostrarRelatorio &&
                <React.Fragment>
                  <AppBar position="static">
                    <Tabs id="viewCliente" variant="scrollable" scrollButtons="auto" value={tab} onChange={this.handleChange} aria-label="simple tabs example">
                      <Tab label="Por Unidade" {...a11yProps(0)} />
                      <Tab label="Por Colaborador" {...a11yProps(1)} />
                      <Tab label="Por Função" {...a11yProps(2)} />
                      <Tab label="Regras" {...a11yProps(3)} />
                    </Tabs>
                  </AppBar>
                  <TabPanel value={tab} index={0}>
                    <Grid container spacing={1} direction="row">
                      <Grid item md={12} xs={12}>
                        <Chart 
                          chartType="BarChart" 
                          width="100%"
                          data={this.state.dataChart}
                          height={`${(this.state.dataChart.length - 1) * 50}px`}
                          chartEvents={[
                            {
                              eventName: 'select',
                              callback: ({ chartWrapper }) => {
                                const chart = chartWrapper.getChart()
                                const selection = chart.getSelection()
                                if (selection.length === 1) {
                                  const [selectedItem] = selection
                                  const { row } = selectedItem

                                  if(this.state.minhaUnidade.unin_tipo === 'FRANQUEADOR'){
                                    this.openModalUnidade(relatoriosFiltrado.list[row])
                                  }else if (this.state.minhaUnidade.unin_tipo === 'FRANQUEADO' && this.state.minhaUnidade.unin_id === relatoriosFiltrado.list[row].unin_superior_id){
                                    this.openModalUnidade(relatoriosFiltrado.list[row])
                                  }else if(this.state.minhaUnidade.unin_tipo === 'UNIDADE' && this.state.minhaUnidade.unin_id === relatoriosFiltrado.list[row].unin_id){
                                    this.openModalUnidade(relatoriosFiltrado.list[row])
                                  }        
                                }
                              },
                            },
                          ]}
                          options={{
                            chartArea: {height: '90%'},
                            title: 'Ranking Geral Unidades',
                            baseline: 0,
                            hAxis: {
                              title: 'Pontos',
                              minValue: 0
                            },
                            vAxis: {
                              title: 'Unidades'
                            },
                            legend: "none"
                          }} 
                        />
                      </Grid>
                    </Grid>
                  </TabPanel>  
                  <TabPanel value={tab} index={1}>
                    <Grid container spacing={1} direction="row">
                      <Grid item md={12} xs={12}>
                        <Chart
                          width={'100%'}
                          height={`${qtdRowColaborador * 50}px`}
                          chartType="BarChart"
                          data={this.state.porColaborador}
                          options={{
                            chartArea: { width: '60%', height: '90%'},
                            timeline: { groupByRowLabel: false },
                            title: 'Ranking Geral Colaboradores',
                            isStacked: true,
                          }}
                          // For tests
                          rootProps={{ 'data-testid': '3' }}
                        />
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={tab} index={2}>
                    <br />
                    {this.state.porCargo.map((cargo, key) => {
                      return (
                        <React.Fragment>
                          <Grid container spacing={1} direction="row">
                            <Grid item md={12} xs={12}>
                              <Chart
                                width={'100%'}
                                height={`${(cargo.colaboradores.length ) * 50}px`}
                                chartType="BarChart"
                                data={cargo.colaboradores}
                                options={{
                                  chartArea: { width: '60%', height: '70%'},
                                  timeline: { groupByRowLabel: false },
                                  title: cargo.func_descricao,
                                  isStacked: true,
                                }}
                                // For tests
                                rootProps={{ 'data-testid': '3' }}
                              />
                            </Grid>
                          </Grid>
                          <hr />
                        </React.Fragment>
                      )
                    })}
                  </TabPanel>
                  <TabPanel value={tab} index={3}>
                    {listaPontuacao.map(lista => {
                      return (
                        <React.Fragment>
                          <Grid container spacing={1} direction="row" className="mg_top_10">
                            <Grid item md={12} xs={12} sm={12}>
                              <h4>Tabela: {lista.pfh_nome} Data de Inicio: {moment(lista.pfh_data_inicio).format('DD/MM/YYYY')} Data Final: {moment(lista.pfh_data_fim).format('DD/MM/YYYY')}</h4>
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} direction="row" className="mg_top_10">
                            <Grid item md={12} xs={12} sm={12}>
                              <h5>Notas:</h5>
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} direction="row" className="mg_top_10">
                            <Grid item md={12} xs={12} sm={12}>
                              {this.gerarDescricao(lista.pfh_descricao)}
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} direction="row" className="mg_top_10">
                            <Grid item md={6} xs={12} sm={12}>
                              <h5>Pontuação dos Cursos:</h5>
                              <Table
                                headCell={cabecalhoTabelaPontosCurso}
                                rows={lista.pontos_curso.sort((a, b) => (a.item > b.item) ? 1 : ((b.item > a.item) ? -1 : 0))}
                                acoes={acoesTabelaPontosCurso}
                                qtdLinhas={lista.pontos_curso.length}
                                desabilitaRodape={true}
                              />
                            </Grid>
                            <Grid item md={4} xs={12} sm={12}>
                              <h5>Pontuação dos Produtos:</h5>
                              <Table
                                headCell={cabecalhoTabelaPontosProduto}
                                rows={lista.pontos_produto.sort((a, b) => (a.item > b.item) ? 1 : ((b.item > a.item) ? -1 : 0))}
                                acoes={acoesTabelaPontosProduto}
                                qtdLinhas={lista.pontos_produto.length}
                                desabilitaRodape={true}
                              />
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      )
                    })}
                  </TabPanel>
                </React.Fragment>
              }
            </React.Fragment>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
          <ModalFlyHighUnidade
            open={this.state.modalFlyHighUnidade}
						unidadeSelecionada={this.state.unidadeSelecionada}
						handleClose={e => this.handleCloseFlyHighUnidade(e)}
						subCabecalhoTabela= {this.state.subCabecalhoTabela}
						subAcoesTabela= {this.state.subAcoesTabela}
						subRelatorios={this.state.subRelatorios}
						subDataChartGeral={this.state.subDataChartGeral}
						subDataChartItem={this.state.subDataChartItem}
          />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div>
    )
  }
}