import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CreateIcon from '@material-ui/icons/Create';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PanToolIcon from '@material-ui/icons/PanTool';
import PrintIcon from '@material-ui/icons/Print';
import CachedIcon from '@material-ui/icons/Cached';
import { Link } from 'react-router-dom';
import { Grid, Button, TextField, Checkbox } from '@material-ui/core'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import Color from 'color';
import real from './../services/real'
import moment from 'moment'
import EmailIcon from '@material-ui/icons/Email';

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: '#FFF',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#2c4e82',
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const DarkTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.black,
    color: '#fff',
    boxShadow: theme.shadows[1],
    fontSize: 16,
    width: '500px'
  }
}))(Tooltip);

const ColorDarkGreenButton = withStyles((theme) => ({
  root: {
    color: Color('#006400').alpha(0.9).string()
  },
}))(IconButton);

const ColorDarkOrange = withStyles((theme) => ({
  root: {
    color: Color('#FF8C00').alpha(0.9).string(),
  },
}))(Button);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  let stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  stabilizedThis = stabilizedThis.map((el) => el[0]);

  return stabilizedThis
}

function renderClassFinanceiro(param) {
  if (param === 'VENCIDO') {
    return '#FFB6C1'
  } else if (param === 'A VENCER') {
    return '#FFFFE0'
  } else if (param === 'PAGAMENTO APROVADO') {
    return '#87CEFA'
  } else if (param === 'BAIXADO') {
    return '#98FB98'
  } else if (param === 'CANCELADO' || param === 'ESTORNADO') {
    return '#FFFAFA'
  }
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, acoes } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = props.headCell

  return (
    <TableHead>
      <StyledTableRow>
        {headCells.map(headCell => {
          return (
            <StyledTableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={(orderBy === headCell.id || orderBy === headCell.orderBy) ? order : 'asc'}
                onClick={createSortHandler(headCell.orderBy || headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </StyledTableCell>
          )
        })}
        {acoes.length === 1 &&
          <StyledTableCell style={{ width: 100 }} align={'center'}>
            Ações
          </StyledTableCell>
        }
        {acoes.length === 2 &&
          <StyledTableCell style={{ width: 140 }} align={'center'}>
            Ações
          </StyledTableCell>
        }
        {acoes.length === 3 &&
          <StyledTableCell style={{ width: 170 }} align={'center'}>
            Ações
          </StyledTableCell>
        }
        {acoes.length === 4 &&
          <StyledTableCell style={{ width: 200 }} align={'center'}>
            Ações
          </StyledTableCell>
        }
      </StyledTableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function renderTooltipScheduleAluno(agendas, row) {
  const rows = []
  
  let key = 1

  for (const agenda of agendas) {
    if(agenda.ageaula_id !== row._id)
    rows.push(<p className='texto_tooltip' key={key}>Data: {moment(agenda.ageaula_data_hora).format('DD/MM/YYYY HH:mm')} - Aula: {agenda.aula.aula_nome} - Professor: {agenda.professor.funcionario.fisica.pesf_nome}</p>)
    
    key++
  }

  return rows
}

export default function EnhancedTable(props) {
  const { acoes, noRemove, headCell, rows, conciliar, ordem, ordernar, heightRows, registrarRows } = props

  const classes = useStyles();
  const [order, setOrder] = React.useState(ordem || 'asc');
  const [orderBy, setOrderBy] = React.useState(ordernar || '');
  const [selected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  // const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(props.qtdLinhas ? props.qtdLinhas : 10);

  const habilitaRodape = props.desabilitaRodape ? false : true

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';

    localStorage.setItem('ordenacao', JSON.stringify({
      property,
      isAsc
    }));

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const rowsTable = stableSort(rows, getComparator(order, orderBy))

  if(registrarRows){
    registrarRows(rowsTable)
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCell={headCell}
              acoes={acoes}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row._id ? (row.valpag_id ? `${row._id}-${row['valpag_id']}` : row._id) : row.index}
                      // key={row._id ? row._id : row.index}
                      selected={isItemSelected}
                      style={{
                        backgroundColor: row.totalizador ? '#A9A9A9' : renderClassFinanceiro(row.parcont_situacao || ''),
                        borderTop: row.borderTop ? '3px solid #000' : ''
                      }}
                    >
                      {headCell.map(param => {
                        if (param.id === 'curso_ead') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'default'}
                            >
                              {row[param.id] &&
                                <Tooltip title='Copiar Link EAD' aria-label="add">
                                  <span onClick={() => props.copiarLink(row)} style={{cursor: 'pointer'}}> {row[param.id] ? 'Sim': 'Não'} </span>
                                </Tooltip>
                              }
                              {!row[param.id] &&
                                <span> {row[param.id] ? 'Sim': 'Não'} </span>
                              }
                            </TableCell>
                          )
                        } else if (row[param.id] === true) {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'default'}
                            >
                              Sim
                            </TableCell>
                          )
                        } else if (row[param.id] === false) {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'default'}
                            >
                              Não
                            </TableCell>
                          )
                        } else if (param.id === 'url') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'default'}
                            >
                              <img className="imagemTable" src={row[param.id]} alt="foto" />
                            </TableCell>
                          )
                        } else if (param.id === 'qtd_alunos') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'default'}
                            >
                              <Grid container spacing={1} direction="row">
                                <Grid item md={2} sm={2} xs={3}>
                                  {row[param.id]}
                                </Grid>
                                <Grid item md={10} sm={10} xs={9}>
                                  {row.alunos.map(aluno => {
                                    return (
                                      <React.Fragment>
                                        <Link key={aluno.aluno_id} to={`/aluno/view/${aluno.aluno_matricula}`}>
                                          <span className='alunoTabela'>{aluno.aluno_matricula} - {aluno.fisica.pesf_nome}{aluno.fisica.pesf_sobrenome ? ` ${aluno.fisica.pesf_sobrenome}` : ''}</span>
                                        </Link>
                                        {(aluno.agenda_aulas.length - 1) > 0 &&
                                          <DarkTooltip
                                            title={renderTooltipScheduleAluno(aluno.agenda_aulas, row)}
                                            aria-label="add"
                                          >
                                            <span className='alunoTabelaAgendas'> (+{aluno.agenda_aulas.length - 1})</span>
                                          </DarkTooltip>
                                        }
                                        <br />
                                      </React.Fragment>
                                    )
                                  })}
                                </Grid>
                              </Grid>
                            </TableCell>
                          )
                        } else if (param.id === 'qtd_alunos2') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'default'}
                            >
                              <Grid container spacing={1} direction="row">
                                <Grid item md={2} sm={2} xs={3}>
                                  {row[param.id]}
                                </Grid>
                                <Grid item md={10} sm={10} xs={9}>
                                  {row.alunos.map(aluno => {
                                    return (
                                      <Link key={aluno.aluno_id} to={`/aluno/view/${aluno.aluno_matricula}`}>
                                        <p className='alunoTabela'>{aluno.aluno_matricula} - {aluno.fisica.pesf_nome}</p>
                                      </Link>
                                    )
                                  })}
                                </Grid>
                              </Grid>
                            </TableCell>
                          )
                        } else if (param.id === 'aditcontm_descricao'){
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'default'}
                            >
                              <div dangerouslySetInnerHTML={{__html: row[param.id]}}></div>
                            </TableCell>
                          )
                        } else if (param.id === 'aula_aluno_padrao') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'default'}
                            >

                              {!row.limiteUnidade &&
                                <span>{row[param.id]}</span>
                              }
                              {row.limiteUnidade &&
                                <span><s className='color-grey'>{row[param.id]}</s> {row.limiteUnidade}</span>
                              }
                              {props.habilitaQtdAlunos &&
                                <Tooltip title='Alterar Limite Alunos' aria-label="add">
                                  <IconButton onClick={e => props.editarQtdAlunos(row)} color="primary" aria-label="editar">
                                    <CachedIcon />
                                  </IconButton>
                                </Tooltip>
                              }
                            </TableCell>
                          )
                        } else if (param.id === 'contpr_descricao') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'default'}
                            >
                              {row.aluno_matricula &&
                                <p style={{ marginBottom: 0, marginTop: 0, fontSize: '12px' }}><b>{row.aluno_matricula} - {row.aluno_nome}</b></p>
                              }
                              <span>{row[param.id]}</span>
                            </TableCell>
                          )
                        } else if (param.id === 'qtdAulas') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'default'}
                            >
                              {row[param.id]}

                              <IconButton color="primary" aria-label="view" onClick={e => props.viewRow(row.idsAulas, 'aluno-aulas-professor', {})} disabled={row[param.id] === 0}>
                                <SearchIcon />
                              </IconButton>

                            </TableCell>
                          )
                        } else if (param.id === 'qtdAulasNoShow') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'default'}
                            >
                              {row[param.id]}

                              <IconButton color="primary" aria-label="view" onClick={e => props.viewRow(row.idsAulasNoShow, 'aluno-aulas-professor', {})} disabled={row[param.id] === 0}>
                                <SearchIcon />
                              </IconButton>
                            </TableCell>
                          )
                        } else if (param.id === 'matricula') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'default'}
                            >
                              {row[param.id]}

                              <IconButton color="primary" aria-label="view" onClick={e => props.viewRow(row.idsMatricula, 'contratos-alunos', {})} disabled={row[param.id] === 0}>
                                <SearchIcon />
                              </IconButton>

                            </TableCell>
                          )
                        } else if (param.id === 'rematricula') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'default'}
                            >
                              {row[param.id]}

                              <IconButton color="primary" aria-label="view" onClick={e => props.viewRow(row.idsRematricula, 'contratos-alunos', {})} disabled={row[param.id] === 0}>
                                <SearchIcon />
                              </IconButton>

                            </TableCell>
                          )
                        } else if (param.id === 'movcontfin_valor_caixa') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'default'}
                            >
                              <p style={{ color: row.movcontfin_tipo === 'SAQUE' ? '#FF0000' : '#006400', textAlign: 'right' }}>R$ {row.movcontfin_valor_caixa}</p>
                            </TableCell>
                          )
                        } else if (param.id === 'total_credito') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'default'}
                            >
                              <p style={{ color: '#006400', marginBottom: '0px' }}>{row.total_credito}</p>
                            </TableCell>
                          )
                        } else if (param.id === 'total_debito') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'default'}
                            >
                              <p style={{ color: '#FF0000', marginBottom: '0px' }}>{row.total_debito}</p>
                            </TableCell>
                          )
                        } else if (param.id === 'valor_debito') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'default'}
                            >
                              <p style={{ color: '#FF0000', marginBottom: '0px' }}>{row.valor_debito}</p>
                            </TableCell>
                          )
                        } else if (param.id === 'valor_credito') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'default'}
                            >
                              <p style={{ color: '#006400', marginBottom: '0px' }}>{row.valor_credito}</p>
                            </TableCell>
                          )
                        } else if (param.id === 'valor_recebido') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'default'}
                            >
                              R$ {real(row.valor_recebido)}
                            </TableCell>
                          )
                        }else if (param.id === 'austemp_codigo') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'default'}
                            >
                              <Tooltip title='Abrir Link' aria-label="add">
                                <Link to={`/assinatura_ausencia/${row._id}`} target="_blank">
                                  <span>{row[param.id]}</span>
                                </Link>
                              </Tooltip>
                            </TableCell>
                          )
                        }else if (param.id === 'hisp_codigo') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'default'}
                            >
                              <Tooltip title='Abrir Link' aria-label="add">
                                <Link to={`/assinatura_prorrogacao/${row._id}`} target="_blank">
                                  <span>{row[param.id]}</span>
                                </Link>
                              </Tooltip>
                            </TableCell>
                          )
                        }else if (param.id === 'aditcontm_codigo') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'default'}
                            >
                              <Tooltip title='Abrir Link' aria-label="add">
                                <Link to={`/assinatura_aditivo/${row._id}`} target="_blank">
                                  <span>{row[param.id]}</span>
                                </Link>
                              </Tooltip>
                            </TableCell>
                          )
                        } else if (param.id === 'movcontfin_valor') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align='right'
                              padding={param.disablePadding ? 'none' : 'default'}
                            >
                              {row['movcontfin_tipo'] === 'SAQUE' &&
                                <p style={{ color: '#FF0000', marginBottom: '0px' }}>- {row.movcontfin_valor}</p>
                              }
                              {row['movcontfin_tipo'] === 'DEPOSITO' &&
                                <p style={{ color: '#006400', marginBottom: '0px' }}>{row.movcontfin_valor}</p>
                              }
                            </TableCell>
                          )
                        } else if (param.id === 'conta_destino') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'default'}
                            >
                              {row.formpag_descricao === 'Dinheiro' &&
                                <TextField style={{ marginTop: '8px', marginBottom: '8px' }} className="input" label="Conta*" variant="outlined" size="small" value={props.contasFinanceira.filter(param => param.contfin_cofre === true)[0].contfin_descricao} InputLabelProps={{ shrink: true }} disabled={true} />
                              }
                              {row.formpag_descricao !== 'Dinheiro' &&
                                <TextField
                                  style={{ marginTop: '8px', marginBottom: '8px' }}
                                  id="standard-select-currency"
                                  select
                                  variant="outlined"
                                  className="input"
                                  fullWidth
                                  label="Conta*"
                                  size="small"
                                  SelectProps={{
                                    native: true,
                                  }}
                                  name="contfin_id"
                                  value={row.contfin_id}
                                  onChangeCapture={(e) => props.updateFieldConta(e, row.index)}
                                  InputLabelProps={{ shrink: true }}
                                >
                                  <option value="">--Escolha--</option>
                                  {props.contasFinanceira.filter(param => param.contfin_tipo_conta === 'BANCO').sort((a, b) => (a.contfin_ordem > b.contfin_ordem) ? 1 : ((b.contfin_ordem > a.contfin_ordem) ? -1 : 0)).map(conta => {
                                    return (
                                      <option key={conta.contfin_id} value={conta.contfin_id}>{conta.contfin_ordem} - {conta.contfin_descricao}</option>
                                    )
                                  })}
                                </TextField>
                              }

                            </TableCell>
                          )
                        } else if (param.id === 'professor_schedule') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'default'}
                            >
                              {props.permissoesAlteraProfessor.perm_alterar &&
                                <IconButton onClick={e => props.trocarProfessor(row)} color="primary" aria-label="view">
                                  <SwapHorizIcon />
                                </IconButton>
                              }
                              {row[param.id]}
                            </TableCell>
                          )
                        } else if (param.id === 'porcentagem') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'default'}
                            >
                              {(row[param.id] !== '' &&  row[param.id] !== 'msg') &&
                                <div className='porcentagem'>
                                  <div style={{width: `${row[param.id]}%`}}>
                                    {row[param.id]}%
                                  </div>
                                </div>
                              }
                              {row[param.id] === 'msg' &&
                                <span>Novos Alunos + Rematrículas + Prorrogações - Encerramento - Inativações - Cancelamentos</span>
                              }
                            </TableCell>
                          )
                        } else if (param.id === 'qtd_matriculas' || param.id === 'qtd_rematriculas' || param.id === 'qtd_prorrogacao'
                        || param.id === 'qtd_cancelados' || param.id === 'qtd_inativados' || param.id === 'qtd_encerrados'
                        || param.id === 'qtd_transf_entrada' || param.id === 'qtd_transf_saida' || param.id === 'qtd_ativados') {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'default'}
                            >
                              <span>
                                {row[param.id]}
                              </span>
                              {row['unidade'] !== 'Total' &&
                                <IconButton 
                                  color="primary" 
                                  aria-label="view" 
                                  onClick={e => props.viewRow(row[`${param.id.replace('qtd_', '')}`], 'contratos-alunos', `${row['unidade']} - ${param.label}`)} 
                                  disabled={row[param.id] === 0}
                                >
                                  <SearchIcon />
                                </IconButton>
                              }
                            </TableCell>
                          )
                        } else {
                          return (
                            <TableCell
                              key={param.id}
                              component="th"
                              id={labelId}
                              scope="row"
                              align={param.numeric ? 'right' : 'left'}
                              style={{fontSize: props.tamanhoFonte}}
                              padding={param.disablePadding ? 'none' : 'default'}
                            >
                              <span>
                                {row[param.id]}
                              </span>
                            </TableCell>
                          )
                        }
                      })}
                      {acoes.length > 0 &&
                        <TableCell align={'center'}>
                          {acoes.map(acao => {
                            if (acao === "view") {
                              return (
                                <Tooltip title='Visualizar' aria-label="add">
                                  <Link key={acao} to={`${props.urlView}${row._id}`}>
                                    <IconButton color="primary" aria-label="view">
                                      <VisibilityIcon />
                                    </IconButton>
                                  </Link>
                                </Tooltip>
                              )
                            } else if (acao === "viewDemonstrativo") {
                              return (
                                <Tooltip title='Visualizar' aria-label="add">
                                  <Link key={acao} to={`${props.urlView}${row['parcont_datapagamento_sem_form']}/${row['pesf_id']}/${row['unin_id']}`}>
                                    <IconButton color="primary" aria-label="view">
                                      <VisibilityIcon />
                                    </IconButton>
                                  </Link>
                                </Tooltip>
                              )
                            } else if (acao === "downloadFile") {
                              return (
                                <Tooltip title='Download Arquivo' aria-label="add">
                                  <Button color='primary' onClick={() => props.downloadFile(row)} aria-label="view">
                                    <PrintIcon />
                                  </Button>
                                </Tooltip>
                              )
                            } else if (acao === "removeFile") {
                              return (
                                <Tooltip title='Remover Arquivo' aria-label="add">
                                  <Button color='secondary' onClick={() => props.removeFile(row)} aria-label="view">
                                    <DeleteIcon />
                                  </Button>
                                </Tooltip>
                              )
                            } else if (acao === "visualizarObservacao") {
                              if(row.hisp_observacao){
                                return (
                                  <Tooltip title='Visualizar Observação' aria-label="add">
                                    <ColorDarkOrange onClick={() => props.openObservacao(row)} aria-label="view">
                                      <VisibilityIcon />
                                    </ColorDarkOrange>
                                  </Tooltip>
                                )
                              }
                            } else if (acao === "reenviarAssinatura") {
                              if(row.aditcontm_situacao === "Aguardando Assinatura" || row.hisp_situacao === "Aguardando Assinatura" ||
                                (!row.ativo && row.austemp_codigo !== "")
                              ){
                                return (
                                  <Tooltip title='Reenviar Email' aria-label="add">
                                    <ColorDarkGreenButton onClick={() => props.reenviarAssinatura(row)} aria-label="view">
                                      <EmailIcon />
                                    </ColorDarkGreenButton>
                                  </Tooltip>
                                )
                              }
                            } else if (acao === "viewAntesDepois") {
                              return (
                                <Tooltip title='Visualizar Antes e Depois' aria-label="add">
                                  <IconButton color="primary" aria-label="view" onClick={() => props.viewAntesDepois(row)}>
                                    <VisibilityIcon />
                                  </IconButton>
                                </Tooltip>
                              )
                            } else if (acao === "viewContrato") {
                              return (
                                <Tooltip title='Visualizar' aria-label="add">
                                  <IconButton color="primary" aria-label="view" onClick={e => props.viewContrato(row)}>
                                    <VisibilityIcon />
                                  </IconButton>
                                </Tooltip>
                              )
                            } else if (acao === "viewContratosAlunos") {
                              return (
                                <Tooltip title='Visualizar' aria-label="add">
                                  <IconButton color="primary" aria-label="view" onClick={e => props.viewRow(row.idsContratos, 'contratos-alunos', row.status)}>
                                    <SearchIcon />
                                  </IconButton>
                                </Tooltip>
                              )
                            } else if (acao === "viewAgenda") {
                              return (
                                <Tooltip title='Visualizar' aria-label="add">
                                  <Link key={acao} to={`${props.urlView}${row.aluno_matricula}/agenda`}>
                                    <IconButton color="primary" aria-label="view">
                                      <VisibilityIcon />
                                    </IconButton>
                                  </Link>
                                </Tooltip>
                              )
                            } else if (acao === "viewContratoRel") {
                              return (
                                <Tooltip title='Visualizar' aria-label="add">
                                  <Link key={acao} to={`${props.urlView}${row.aluno_matricula}/contrato`}>
                                    <IconButton color="primary" aria-label="view">
                                      <VisibilityIcon />
                                    </IconButton>
                                  </Link>
                                </Tooltip>
                              )
                            } else if (acao === "viewAluno") {
                              if (row.aluno_matricula) {
                                return (
                                  <Tooltip title='Visualizar' aria-label="add">
                                    <Link key={acao} to={`${props.urlView}${row.aluno_matricula}`} target="_blank" >
                                      <IconButton color="primary" aria-label="view">
                                        <VisibilityIcon />
                                      </IconButton>
                                    </Link>
                                  </Tooltip>
                                )
                              } else {
                                return (
                                  <Tooltip title='Visualizar' aria-label="add">
                                    <Link key={acao} to={`${props.urlView}${row._id}`}>
                                      <IconButton color="primary" aria-label="view">
                                        <VisibilityIcon />
                                      </IconButton>
                                    </Link>
                                  </Tooltip>
                                )
                              }
                            } else if (acao === "update") {
                              if (props.tela === 'Perfil') {
                                // if (idUnidadeTrabalho === row.dono_unin_id) {
                                //   return (
                                //     <Tooltip title='Editar' aria-label="add">
                                //       {(() => {
                                //         if (typeof props.urlUpdate == 'function') {
                                //           return (
                                //             <IconButton
                                //               color="secondary"
                                //               aria-label="view"
                                //               onClick={e => props.urlUpdate(row)}
                                //             >
                                //               <CreateIcon />
                                //             </IconButton>
                                //           )
                                //         } else {
                                //           return (
                                //             <Link key={acao} to={`${props.urlUpdate}${row._id}`}>
                                //               <IconButton color="secondary" aria-label="view">
                                //                 <CreateIcon />
                                //               </IconButton>
                                //             </Link>
                                //           )
                                //         }
                                //       })()}
                                //     </Tooltip>
                                //   )
                                // }
                                return (
                                  <Tooltip title='Editar' aria-label="add">
                                    {(() => {
                                      if (typeof props.urlUpdate == 'function') {
                                        return (
                                          <IconButton
                                            color="secondary"
                                            aria-label="view"
                                            onClick={e => props.urlUpdate(row)}
                                          >
                                            <CreateIcon />
                                          </IconButton>
                                        )
                                      } else {
                                        return (
                                          <Link key={acao} to={`${props.urlUpdate}${row._id}`}>
                                            <IconButton color="secondary" aria-label="view">
                                              <CreateIcon />
                                            </IconButton>
                                          </Link>
                                        )
                                      }
                                    })()}
                                  </Tooltip>
                                )
                              } else {
                                if (row.descricao === 'Administrador do Sistema') {
                                  return false
                                } else {
                                  return (
                                    <Tooltip key={acao} title='Editar' aria-label="add">
                                      {(() => {
                                        if (typeof props.urlUpdate == 'function') {
                                          return (
                                            <IconButton
                                              color="secondary"
                                              aria-label="view"
                                              onClick={e => props.urlUpdate(row)}
                                            >
                                              <CreateIcon />
                                            </IconButton>
                                          )
                                        } else {
                                          return (
                                            <Link key={acao} to={`${props.urlUpdate}${row._id}`}>
                                              <IconButton color="secondary" aria-label="view">
                                                <CreateIcon />
                                              </IconButton>
                                            </Link>
                                          )
                                        }
                                      })()}
                                    </Tooltip>
                                  )
                                }
                              }
                            } else if (acao === "conciliar_mov") {
                              if (row['contfin_conciliavel']) {
                                if (row['movcontfin_conciliavel']) {
                                  if (row['movcontfin_conciliado']) {
                                    return (
                                      <Tooltip key={acao} title='Desconciliar' aria-label="add">
                                        <IconButton onClick={e => props.conciliarMov(row)} color="primary" aria-label="editar">
                                          <CheckCircleOutlineIcon />
                                        </IconButton>
                                      </Tooltip>
                                    )
                                  } else {
                                    return (
                                      <Tooltip key={acao} title='Conciliar' aria-label="add">
                                        <IconButton onClick={e => props.conciliarMov(row)} color="secondary" aria-label="editar">
                                          <RadioButtonUncheckedIcon />
                                        </IconButton>
                                      </Tooltip>
                                    )
                                  }
                                }
                              }
                            } else if (acao === "editar") {
                              return (
                                <Tooltip key={acao} title='Editar' aria-label="add">
                                  <React.Fragment>
                                    <IconButton onClick={e => props.editar(row.index)} color="primary" aria-label="editar">
                                      <CreateIcon />
                                    </IconButton>
                                  </React.Fragment>
                                </Tooltip>
                              )
                            } else if (acao === "editarContrato") {
                              console.log(row)
                              return (
                                <Tooltip key={acao} title='Editar' aria-label="add">
                                  <Link key={acao} to={`/aluno/cadastro/${row.contm_aluno_id}/${row._id}`}>
                                    <IconButton disabled={row.stcontmat_situacao !== "Aguardando Aprovação" && row.stcontmat_situacao !== "Aguardando Assinatura"} color="secondary" aria-label="view">
                                      <CreateIcon/>
                                    </IconButton>
                                  </Link>
                                </Tooltip>
                              )
                            } else if (acao === "imprimir") {
                              return (
                                <Tooltip title='Imprimir' aria-label="add">
                                  <React.Fragment key={acao}>
                                    <IconButton onClick={e => props.imprimir(row)} color="primary" aria-label="editar">
                                      <PrintIcon />
                                    </IconButton>
                                  </React.Fragment>
                                </Tooltip>
                              )
                            } else if (acao === "imprimirDocVers") {
                              return (
                                <Tooltip title='Imprimir Doc' aria-label="add">
                                  <React.Fragment key={acao}>
                                    <IconButton onClick={e => props.imprimir(row)} color="primary" aria-label="editar">
                                      <PrintIcon />
                                    </IconButton>
                                  </React.Fragment>
                                </Tooltip>
                              )
                            } else if (acao === "imprimirDoc") {
                              return (
                                <Tooltip key={acao} title='Imprimir Contrato' aria-label="add">
                                  <IconButton onClick={e => props.imprimirDoc(row)} color="secondary" aria-label="editar">
                                    <PrintIcon />
                                  </IconButton>
                                </Tooltip>
                              )
                            } else if (acao === "desativar") {
                              if (row.ativo) {
                                return (
                                  <Tooltip title='Excluir' aria-label="add">
                                    <React.Fragment key={acao}>
                                      <IconButton onClick={e => props.desativar(row)} color="secondary" aria-label="editar">
                                        <CloseIcon />
                                      </IconButton>
                                    </React.Fragment>
                                  </Tooltip>
                                )
                              }
                            } else if (acao === "remove") {
                              return (
                                <Tooltip key={acao} title='Remover' aria-label="add">
                                  <React.Fragment>
                                    {!noRemove &&
                                      <IconButton onClick={e => props.remove(row.index)} color="secondary" aria-label="remove">
                                        <DeleteIcon />
                                      </IconButton>
                                    }
                                  </React.Fragment>
                                </Tooltip>
                              )
                            } else if (acao === "removerAluno") {
                              return (
                                <Tooltip key={acao} title='Remover' aria-label="add">
                                  <React.Fragment>
                                    <IconButton onClick={e => props.removerAluno(row)} color="secondary" aria-label="remove" disabled={row.aluno_agenda_id}>
                                      <DeleteIcon />
                                    </IconButton>
                                  </React.Fragment>
                                </Tooltip>
                              )
                            } else if (acao === 'removerFeriado'){
                              if(row['fer_tipo'] === "NACIONAL" && props.unin_tipo === 'FRANQUEADOR'){
                                return (
                                  <Tooltip title='Remover' aria-label="add">
                                    <React.Fragment key={acao}>
                                      <IconButton onClick={e => props.remover(row)} color="primary" aria-label="remove">
                                        <DeleteIcon />
                                      </IconButton>
                                    </React.Fragment>
                                  </Tooltip>
                                )
                              }else if((row['fer_tipo'] === "ESTADUAL" || row['fer_tipo'] === "MUNICIPAL") && props.unin_tipo === 'FRANQUEADO'){
                                return (
                                  <Tooltip title='Remover' aria-label="add">
                                    <React.Fragment key={acao}>
                                      <IconButton onClick={e => props.remover(row)} color="primary" aria-label="remove">
                                        <DeleteIcon />
                                      </IconButton>
                                    </React.Fragment>
                                  </Tooltip>
                                )
                              }
                              return false
                            } else if (acao === "estornarProrrogacao") {
                              if (row.hisp_ativo) {
                                return (
                                  <Tooltip title='Estornar' aria-label="add">
                                    <IconButton onClick={e => props.estornarProrrogacao(row)} color="secondary" aria-label="remove">
                                      <CloseIcon />
                                    </IconButton>
                                  </Tooltip>
                                )
                              }
                            } else if (acao === "removeTabela") {
                              return (
                                <Tooltip key={acao} title='Remover' aria-label="add">
                                  <React.Fragment>
                                    <IconButton onClick={e => props.remove(row.index)} color="secondary" aria-label="remove">
                                      <DeleteIcon />
                                    </IconButton>
                                  </React.Fragment>
                                </Tooltip>
                              )
                            } else if (acao === "removeCurso") {
                              return (
                                <Tooltip title='Remover' aria-label="add">
                                  <React.Fragment key={acao}>
                                    {!row._id &&
                                      <IconButton onClick={e => props.remove(row.index)} color="secondary" aria-label="remove">
                                        <DeleteIcon />
                                      </IconButton>
                                    }
                                  </React.Fragment>
                                </Tooltip>
                              )
                            } else if (acao === "conciliar") {
                              return (
                                <React.Fragment key={acao}>
                                  <Button onClick={e => conciliar(row)} className="btn-conciliar" size="small" variant="contained" color="primary">
                                    Conciliar
                                  </Button>
                                </React.Fragment>
                              )
                            } else if (acao === "updateVersaoDoc") {
                              return (
                                <Tooltip title='Editar' aria-label="add">
                                  <React.Fragment key={acao}>
                                    <IconButton onClick={e => props.editarVersao(row)} color="secondary" aria-label="editar">
                                      <CreateIcon />
                                    </IconButton>
                                  </React.Fragment>
                                </Tooltip>
                              )
                            } else if (acao === "copy") {
                              return (
                                <Tooltip title='Copiar' aria-label="add">
                                  <React.Fragment key={acao}>
                                    <IconButton onClick={e => props.copiarVersao(row)} color="primary" aria-label="editar">
                                      <FileCopyIcon />
                                    </IconButton>
                                  </React.Fragment>
                                </Tooltip>
                              )
                            } else if (acao === "horario") {
                              if (row.aula_tipo_horario === 'FIXO') {
                                return (
                                  <Tooltip title='Horario das Aulas' aria-label="add">
                                    <Link key={acao} to={`${props.urlHorario}${row._id}`}>
                                      <IconButton color="primary" aria-label="view">
                                        <AccessTimeIcon />
                                      </IconButton>
                                    </Link>
                                  </Tooltip>
                                )
                              }
                            } else if (acao === 'selecionar') {
                              if (props.contrato.contm_tabval_id === row._id) {
                                return (
                                  <React.Fragment key={acao}>
                                    <Checkbox
                                      checked={true}
                                      onChange={() => props.updateFieldSelectPlano(row)}
                                      color="primary"
                                      inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                  </React.Fragment>
                                )
                              } else {
                                return (
                                  <React.Fragment key={acao}>
                                    <Checkbox
                                      checked={false}
                                      onChange={() => props.updateFieldSelectPlano(row)}
                                      color="primary"
                                      inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                  </React.Fragment>
                                )
                              }
                            } else if (acao === 'selectVar') {
                              return (
                                <Tooltip title='Selecionar Variavel' aria-label="add">
                                  <IconButton onClick={e => props.selecionarVar(row)} color="primary" aria-label="view">
                                    <PanToolIcon />
                                  </IconButton>
                                </Tooltip>
                              )
                            }else if (acao === 'selecionarAluno') {
                              return (
                                <Tooltip title='Selecionar Aluno' aria-label="add">
                                  <IconButton onClick={e => props.selecionarAluno(row)} color="primary" aria-label="view">
                                    <PanToolIcon />
                                  </IconButton>
                                </Tooltip>
                              )
                            } else if (acao === 'updateFormaPagto') {
                              return (
                                <Tooltip title='Editar Forma de Pagto' aria-label="add">
                                  <IconButton onClick={e => props.editarFormaPagto(row)} color="secondary" aria-label="editar">
                                    <CreateIcon />
                                  </IconButton>
                                </Tooltip>
                              )
                            } else if (acao === 'corrigeSaldo') {
                              return (
                                <Tooltip title='Corrigir Saldo' aria-label="add">
                                  <Link key={acao} to={`${props.urlCorrigeSaldo}${row._id}`}>
                                    <IconButton color="primary" aria-label="view">
                                      <MonetizationOnIcon />
                                    </IconButton>
                                  </Link>
                                </Tooltip>
                              )
                            } else if (acao === 'viewAula') {
                              return (
                                <Tooltip title='Visualizar aula' aria-label="add">
                                  <IconButton onClick={e => props.viewRow(row, 'aulas-agendadas')} color="primary" aria-label="view">
                                    <VisibilityIcon />
                                  </IconButton>
                                </Tooltip>
                              )
                            } else if (acao === 'viewBaixaAula') {
                              return (
                                <Tooltip title='Visualizar Aula' aria-label="add">
                                  <Link key={acao} to={`${props.urlView}${row.data_aula_sem_form}`} target='_blank'>
                                    <IconButton color="primary" aria-label="view">
                                      <SearchIcon />
                                    </IconButton>
                                  </Link>
                                </Tooltip>
                              )
                            } else if (acao === 'transferirCodigo'){
                              return (
                                <Tooltip title='Transferir Código' aria-label="add">
                                  <IconButton onClick={e => props.transferirCodigo(row)} color="primary" aria-label="view">
                                    <SwapHorizIcon/>
                                  </IconButton>
                                </Tooltip>
                              )
                            } else if (acao === 'viewUnidadeRanking'){
                              if(props.minhaUnidade.unin_tipo === 'FRANQUEADOR'){
                                return (
                                  <Tooltip title='Visualizar Unidade' aria-label="add">
                                    <IconButton color="primary" aria-label="view" onClick={e => props.openModalUnidade(row)}>
                                      <SearchIcon />
                                    </IconButton>
                                  </Tooltip>
                                )
                              }else if (props.minhaUnidade.unin_tipo === 'FRANQUEADO' && props.minhaUnidade.unin_id === row['unin_superior_id']){
                                return (
                                  <Tooltip title='Visualizar Unidade' aria-label="add">
                                    <IconButton color="primary" aria-label="view" onClick={e => props.openModalUnidade(row)}>
                                      <SearchIcon />
                                    </IconButton>
                                  </Tooltip>
                                )
                              }else if(props.minhaUnidade.unin_tipo === 'UNIDADE' && props.minhaUnidade.unin_id === row['unin_id']){
                                return (
                                  <Tooltip title='Visualizar Unidade' aria-label="add">
                                    <IconButton color="primary" aria-label="view" onClick={e => props.openModalUnidade(row)}>
                                      <SearchIcon />
                                    </IconButton>
                                  </Tooltip>
                                )
                              }
                            } else if (acao === 'imprimirBoleto'){
                              if(row.habilita_pix_boleto && (row.parcont_situacao === 'A VENCER' ||  row.parcont_situacao === 'VENCIDO') && row.contpr_tipo === 'RECEBER'){
                                return (
                                  <Tooltip title={row.formpag_slug === 'boleto' ? 'Imprimir Boleto' : 'Imprimir Pix'} aria-label="add">
                                    <IconButton onClick={e => props.imprimirBoleto(row)} color="primary" aria-label="view">
                                      <PrintIcon />
                                    </IconButton>
                                  </Tooltip>
                                )
                              }else{
                                return (
                                  <IconButton color="primary" aria-label="view" disabled>
                                    <PrintIcon />
                                  </IconButton>
                                )
                              }
                            } else if (acao === 'selecionarParcela'){
                              const index = props.parcelasSelecionadas.findIndex(param => param === row._id)
                              if(row.parcont_situacao === 'A VENCER'){
                                const data_hoje = moment().format('DD/MM/YYYY')
                                if(data_hoje !== row.parcont_datavencimento){
                                  if(index !== -1){
                                    return (
                                      <Tooltip title='Desconsiderar' aria-label="add">
                                        <IconButton onClick={e => props.selecionarParcela(row)} color="primary" aria-label="editar">
                                          <CheckCircleOutlineIcon />
                                        </IconButton>
                                      </Tooltip>
                                    )
                                  }else{
                                    return (
                                      <Tooltip title='Selecionar' aria-label="add">
                                        <IconButton onClick={e => props.selecionarParcela(row)} color="secondary" aria-label="editar">
                                          <RadioButtonUncheckedIcon />
                                        </IconButton>
                                      </Tooltip>
                                    )
                                  }
                                }else{
                                  return (
                                    <IconButton disabled color="secondary" aria-label="editar">
                                      <RadioButtonUncheckedIcon />
                                    </IconButton>
                                  )
                                }
                              }else{
                                return (
                                  <IconButton disabled color="secondary" aria-label="editar">
                                    <RadioButtonUncheckedIcon />
                                  </IconButton>
                                )
                              }
                            
                            } else if (acao === 'viewBookAulas'){
                              return (
                                <Tooltip title='Visualizar Aulas' aria-label="add">
                                  <IconButton color="primary" aria-label="view" onClick={e => props.openModalAulas(row)}>
                                    <SearchIcon />
                                  </IconButton>
                                </Tooltip>
                              )
                            } else {
                              return false
                            }
                            return false
                          })}
                        </TableCell>
                      }
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (heightRows || 24) * emptyRows }}>
                  <TableCell colSpan={12} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {habilitaRodape &&
          <TablePagination
            rowsPerPageOptions={[10, 15, rows.length]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      </Paper>
    </div >
  );
}
