import React, { Component } from 'react'
import './../Aluno.scss'
import './../../../main/ultil.scss'
import { Grid, TextField, Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveIcon from '@material-ui/icons/Save';
import Table from '../../../components/Table'
import axios from 'axios'
import ModalErro from './../../../components/modals/Erro'
import ModalCancelamento from './../../../components/modals/ConfirmacaoCancelamentoAusencia'
import ModalAulasPeriodo from './../../../components/modals/AulasPeriodoAusencia'
import ModalAulasPeriodoCancelamento from './../../../components/modals/AulasPeriodoAusencia'
import { saveAs } from 'file-saver';
import Alert from './../../../components/templates/Alert'

const moment = require('moment')

const initalState = {
  ausencia: {
    austemp_aluno_id: '',
    austemp_data_inicial: '',
    austemp_data_final: '',
    austemp_motivo: ''
  },
  ausencias: {
    list: []
  },
  ausenciaRemove: {
    austemp_aluno_id: '',
    austemp_data_inicial: '',
    austemp_data_final: '',
    austemp_motivo: ''
  },
  agendas: {
    list: []
  },
  cabecalhoTabela: [
    { id: 'austemp_criado_em', numeric: false, disablePadding: true, label: 'Inserido em', orderBy: 'criado_em_order' },
    { id: 'austemp_data_inicial', numeric: false, disablePadding: true, label: 'Data Inicial', orderBy: 'data_inicial_order' },
    { id: 'austemp_data_final', numeric: false, disablePadding: false, label: 'Data Final', orderBy: 'data_final_order' },
    { id: 'qtd_dias', numeric: false, disablePadding: false, label: 'Dias' },
    { id: 'austemp_motivo', numeric: false, disablePadding: false, label: 'Motivo' },
    { id: 'criado_por', numeric: false, disablePadding: false, label: 'Cadastrado por	' },
    { id: 'ativo', numeric: false, disablePadding: false, label: 'Ativo' },
    { id: 'austemp_codigo', numeric: false, disablePadding: false, label: 'Código' }
  ],
  acoesTabela: [],
  modalErro: false,
  modalCancelamento: false,
  modalAulasPeriodo: false,
  modalAulasPeriodoCancelamento: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  disabledSalvar: false,
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
}

export default class Agenda extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const aluno = this.props.aluno
    const { ausencia } = this.state

    if (aluno.contrato.status.stcontmat_situacao === "APROVADO") {
      this.setState({
        acoesTabela: ['imprimir', 'reenviarAssinatura', 'desativar'],
      })
    } else {
      this.setState({
        acoesTabela: ['imprimir'],
      })
    }

    ausencia.austemp_aluno_id = aluno._id

    this.setState({
      ausencia,
      aluno
    })

    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/ausenciaTemporaria/${aluno.aluno_matricula}`, this.getToken())

      this.setState({
        ausencias: {
          list: data
        }
      })

    } catch (error) {
      console.log(error)
    }

  }

  updateAusencia(event) {
    const { ausencia } = this.state
    ausencia[event.target.name] = event.target.value
    this.setState({ ausencia })
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  handleCloseCancelamento() {
    this.setState({
      modalCancelamento: false
    })
  }

  handleCloseModalAulasPeriodo() {
    this.setState({
      modalAulasPeriodo: false,
      modalAulasPeriodoCancelamento: false,
    })
  }

  backPage(timeout = 1000) {
    setTimeout(() => {
      window.location.reload()
    }, timeout)
    this.setState({
      ausencia: initalState.ausencia
    })
  }

  validateForm() {
    const { ausencia } = this.state

    if (!ausencia.austemp_data_inicial) return false
    if (!ausencia.austemp_data_final) return false
    if (!ausencia.austemp_motivo) return false

    return true
  }

  calcDias() {
    const { ausencia } = this.state

    if (ausencia.austemp_data_inicial !== '' && ausencia.austemp_data_final !== '') {

      const inicio = moment(ausencia.austemp_data_inicial);
      const fim = moment(ausencia.austemp_data_final);
      const duration = moment.duration(fim.diff(inicio));

      // Mostra a diferença em dias
      ausencia.qtd_dias = duration.asDays() + 1;

      this.setState({
        ausencia
      })
    }
  }

  async verificaAulas() {
    this.setState({
      loadingSalvar: true
    })

    if (!this.validateForm()) {
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      this.setState({
        loadingSalvar: false
      })
      return
    }

    try {
      const { ausencia } = this.state

      const dados = {
        data_inicial: ausencia.austemp_data_inicial,
        data_final: ausencia.austemp_data_final
      }

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/agendaAula/byAluno/periodo/${ausencia.austemp_aluno_id}`, dados, this.getToken())

      if (data.length > 0) {
        this.setState({
          loadingSalvar: false
        })
        this.setState({
          agendas: {
            list: data
          },
          modalAulasPeriodo: true
        })
      } else {
        this.salvarAusencia()
      }


    } catch ({ response }) {
      this.setState({
        loadingSalvar: false
      })
      if (response.status === 400 || response.status === 500) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }
  }

  async verificaAulasCancelamento() {
    try {
      const { ausenciaRemove } = this.state

      const dados = {
        data_inicial: ausenciaRemove.austemp_data_inicial,
        data_final: ausenciaRemove.austemp_data_final_nova
      }

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/agendaAula/byAluno/periodo/${ausenciaRemove.austemp_aluno_id}`, dados, this.getToken())

      if (data.length > 0) {
        this.setState({
          agendas: {
            list: data
          },
          modalAulasPeriodoCancelamento: true
        })
      } else {
        this.confirmarCancelamento()
      }


    } catch ({ response }) {
      if (response.status === 400 || response.status === 500) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }
  }

  async salvarAusencia() {
    try {
      this.setState({
        loadingSalvar: true
      })

      const { ausencia } = this.state

      await axios.post(`${process.env.REACT_APP_API_URL}/ausenciaTemporaria`, ausencia, this.getToken())

      this.backPage()

    } catch ({ response }) {
      this.setState({
        loadingSalvar: false
      })
      if (response.status === 400 || response.status === 500) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }
  }

  async imprimir(value) {
    try {
      const aluno = this.props.aluno

      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/ausenciaTemporaria/gerarPDF/${value._id}`, {}, this.getToken())

      const dados = {
        path: data.filename
      }

      const resp = await axios.post(`${process.env.REACT_APP_API_URL}/imprimirPDF`, dados, { responseType: 'blob' })

      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      saveAs(pdfBlob, `Ausencia-Temporaria-${aluno.aluno_matricula}.pdf`);

    } catch ({ response }) {
      if (response.status === 400 || response.status === 500) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }
  }

  updateFieldRemarcar() {
    const { ausenciaRemove } = this.state
    ausenciaRemove.remarcar = !ausenciaRemove.remarcar
    this.setState({ ausenciaRemove })
  }

  async desativar(value) {
    const { ausencias } = this.state

    const ausencia = ausencias.list.filter(param => param.austemp_id === value._id)[0]

    const ausenciaRemove = {
      austemp_id: ausencia.austemp_id,
      austemp_aluno_id: ausencia.austemp_aluno_id,
      austemp_data_inicial_form: moment(ausencia.austemp_data_inicial).format('DD/MM/YYYY'),
      austemp_data_final_form: moment(ausencia.austemp_data_final).format('DD/MM/YYYY'),
      austemp_data_inicial: moment(ausencia.austemp_data_inicial).format('YYYY-MM-DD'),
      austemp_data_final: moment(ausencia.austemp_data_final).format('YYYY-MM-DD'),
      austemp_data_final_nova: moment(new Date()).format('YYYY-MM-DD'),
      austemp_motivo: ausencia.austemp_motivo,
      remarcar: false
    }

    this.setState({
      ausenciaRemove,
      modalCancelamento: true
    })
  }

  updateData(event) {
    const { ausenciaRemove } = this.state
    ausenciaRemove[event.target.name] = event.target.value
    this.setState({ ausenciaRemove })
  }

  async confirmarCancelamento() {
    const { ausenciaRemove } = this.state

    try {

      await axios.put(`${process.env.REACT_APP_API_URL}/ausenciaTemporaria/remarcar/${ausenciaRemove.austemp_id}`, ausenciaRemove, this.getToken())

      this.backPage()

    } catch ({ response }) {
      if (response.status === 400 || response.status === 500) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }
  }

  calcDifDias(data_inicial, data_final) {
    const inicio = moment(data_inicial);
    const fim = moment(data_final);
    const duration = moment.duration(fim.diff(inicio));

    // Mostra a diferença em dias
    return duration.asDays();
  }

  async reenviarAssinatura(row){
    try {
      this.setState({
        alerta: {
          open: true,
          severity: 'info',
          message: 'Reenviando email...'
        }
      })

      await axios.get(`${process.env.REACT_APP_API_URL}/ausenciaTemporaria/reenviarAssinatura/${row._id}`, this.getToken())

      this.setState({
        alerta: {
          open: true,
          severity: 'success',
          autoHideDuration: 3000,
          message: 'Email enviado!'
        }
      })

    }  catch ({response}) {
      this.setState({
        alerta: {
          open: true,
          autoHideDuration: 5000,
          severity: 'error',
          message: response.data.message
        }
      })
      return
    }
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  render() {
    const { ausencia, ausencias, aluno } = this.state
    return (
      <React.Fragment>
        {this.state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Ausência temporária...</p>
            </div>
          </React.Fragment>
        }
        {!this.state.loading &&
          <div>
            {aluno.contrato.status.stcontmat_situacao === 'APROVADO' &&
              <div className='cardEndereco'>
                <Grid container spacing={1} direction="row">
                  <Grid item md={12} xs={12}>
                    <h5>Nova Ausência</h5>
                  </Grid>
                </Grid>
                <hr />
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={2} xs={12} sm={6}>
                    <TextField InputProps={{ inputProps: { max: moment(ausencia.austemp_data_final).format('YYYY-MM-DD') } }} className="input" label="Data Inicial" type='date' variant="outlined" size="small" name="austemp_data_inicial" value={ausencia.austemp_data_inicial} onChange={(e) => this.updateAusencia(e)} onBlur={(e) => this.calcDias(e)} InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item md={2} xs={12} sm={6}>
                    <TextField InputProps={{ inputProps: { min: moment(ausencia.austemp_data_inicial).format('YYYY-MM-DD') } }} className="input" label="Data Final" type='date' variant="outlined" size="small" name="austemp_data_final" value={ausencia.austemp_data_final} onChange={(e) => this.updateAusencia(e)} onBlur={(e) => this.calcDias(e)} InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item md={1} xs={12} sm={6}>
                    <TextField className="input" label="Dias" variant="outlined" size="small" name="qtd_dias" value={ausencia.qtd_dias} InputLabelProps={{ shrink: true }} disabled />
                  </Grid>
                  <Grid item md={5} xs={12} sm={6}>
                    <TextField className="input" label="Motivo" variant="outlined" size="small" name="austemp_motivo" value={ausencia.austemp_motivo} onChange={(e) => this.updateAusencia(e)} InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item md={2} xs={12} sm={6}>
                    <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => this.verificaAulas(e)} disabled={this.state.loadingSalvar}>Salvar</Button>
                  </Grid>
                </Grid>
              </div>
            }
            {aluno.contrato.status.stcontmat_situacao === 'REPROVADO' &&
              <h4>Esse aluno não está aprovado!</h4>
            }
            {aluno.contrato.status.stcontmat_situacao === 'INATIVO' &&
              <h4>Esse aluno não está com o contrato ativo no momento!</h4>
            }
            {aluno.contrato.status.stcontmat_situacao === 'AGUARDANDO_APROVACAO' &&
              <h4>Esse aluno está aguardando a aprovação do contrato!</h4>
            }
            {aluno.contrato.status.stcontmat_situacao === 'TRANSFERIDO' &&
              <h4>Esse aluno foi transferido para outra unidade!</h4>
            }
            {aluno.contrato.status.stcontmat_situacao === 'CANCELADO' &&
              <h4>Esse aluno está com o contrato cancelado!</h4>
            }
            {aluno.contrato.status.stcontmat_situacao === 'ENCERRADO' &&
              <h4>Esse aluno está com o contrato encerrado!</h4>
            }
            {aluno.contrato.status.stcontmat_situacao === 'AGUARDANDO_TRANFERENCIA' &&
              <h4>Esse aluno está aguardando transferência!</h4>
            }
            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={12} xs={12} sm={12}>
                <Table
                  headCell={this.state.cabecalhoTabela}
                  rows={ausencias.list.map(ausencia => {
                    return {
                      _id: ausencia.austemp_id,
                      austemp_data_inicial: moment(ausencia.austemp_data_inicial).format('DD/MM/YYYY'),
                      austemp_data_final: moment(ausencia.austemp_data_final).format('DD/MM/YYYY'),
                      austemp_motivo: ausencia.austemp_motivo,
                      austemp_codigo: ausencia.codigo,
                      ativo: ausencia.austemp_ativo,
                      qtd_dias: this.calcDifDias(ausencia.austemp_data_inicial, ausencia.austemp_data_final) + 1,
                      austemp_criado_em: moment(ausencia.austemp_criado_em).format('DD/MM/YYYY HH:mm'),
                      criado_por: ausencia.usuario.pessoa.fisica ? ausencia.usuario.pessoa.fisica.pesf_nome.split(' ')[0] : 'Migração',
                      criado_em_order: moment(ausencia.austemp_criado_em).format('YYYYMMDDHH:mm'),
                      data_inicial_order: moment(ausencia.austemp_data_inicial).format('YYYYMMDD'),
                      data_final_order: moment(ausencia.austemp_data_final).format('YYYYMMDD'),
                    }
                  })}
                  acoes={this.state.acoesTabela}
                  imprimir={e => this.imprimir(e)}
                  desativar={e => this.desativar(e)}
                  reenviarAssinatura={(e) => this.reenviarAssinatura(e)}
                />
              </Grid>
            </Grid>
          </div>
        }

        <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        
        <ModalCancelamento
          open={this.state.modalCancelamento}
          handleClose={e => this.handleCloseCancelamento(e)}
          dados={this.state.ausenciaRemove}
          updateFieldRemarcar={e => this.updateFieldRemarcar(e)}
          confirmar={e => this.verificaAulasCancelamento(e)}
          voltar={e => this.handleCloseCancelamento(e)}
          updateData={e => this.updateData(e)} />

        <ModalAulasPeriodo
          open={this.state.modalAulasPeriodo}
          handleClose={e => this.handleCloseModalAulasPeriodo(e)}
          confirmar={e => this.salvarAusencia(e)}
          voltar={e => this.handleCloseModalAulasPeriodo(e)}
          dados={this.state.agendas.list}
        />

        <ModalAulasPeriodoCancelamento
          open={this.state.modalAulasPeriodoCancelamento}
          handleClose={e => this.handleCloseModalAulasPeriodo(e)}
          confirmar={e => this.confirmarCancelamento(e)}
          voltar={e => this.handleCloseModalAulasPeriodo(e)}
          dados={this.state.agendas.list}
        />

        <Alert 
          open={this.state.alerta.open}
          handleClose={e => this.handleCloseAlerta()} 
          severity={this.state.alerta.severity}
          message={this.state.alerta.message} 
        />
      </React.Fragment>
    )
  }
}